import React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

function LoadingOverlay() {
  return (
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  );
}

export default LoadingOverlay;
