import React from "react";
import { Feed } from "semantic-ui-react";
var moment = require("moment");

class SingleEvent extends React.Component {
  render() {
    const { event, timestamp } = this.props;
    var date = moment.unix(timestamp / 1000).format("DD MMM YYYY hh:mm a");
    return (
      <Feed.Event>
        <Feed.Label icon="pencil" />
        <Feed.Content date={date} summary={event} />
      </Feed.Event>
    );
  }
}

export default SingleEvent;
