import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import styled from "styled-components";
import {
  InfiniteRowModelModule,
  ModuleRegistry,
  NumberFilterModule,
  PaginationModule,
  TextFilterModule,
  DateFilterModule,
  CustomFilterModule,
  ValidationModule,
} from "ag-grid-community";
import { AgGridReact, useGridFilter } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { Dropdown } from "semantic-ui-react";
import axios from "axios";

import LoadingOverlay from "./LoadingOverlay";

ModuleRegistry.registerModules([
  NumberFilterModule,
  PaginationModule,
  InfiniteRowModelModule,
  TextFilterModule,
  DateFilterModule,
  CustomFilterModule,
  ValidationModule /* Development Only */,
]);

function getExtraParams(filterValues, getFilterParams = () => {}) {
  return getFilterParams(filterValues);
}

// Style that allows dropdowns to appear on top of grid rows
const GridWrapper = styled.div`
  & .ag-row.ag-row-focus {
    z-index: 1;
  }
  & .ag-header-row-column-filter {
    z-index: 1;
  }
`;

const DataTable = forwardRef(({ columnDefs, url, getFilterParams }, ref) => {
  const [loading, setLoading] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: 500, width: "100%" }), []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      floatingFilter: true,
    };
  }, []);
  const getRowId = useCallback(function (params) {
    return `${params.data.id}`;
  }, []);

  const dataSource = {
    rowCount: undefined,
    getRows: (params) => {
      setLoading(true);
      const getParams = {
        startRow: params.startRow,
        endRow: params.endRow,
        sort: params.sortModel[0],
        ...getExtraParams(params.filterModel, getFilterParams),
      };
      axios({
        method: "get",
        url,
        params: getParams,
      }).then((res) => {
        if (res.status === 200) {
          const data = res.data;
          const rows = data.rows;
          // if on or after the last page, work out the last row.
          let lastRow = -1;
          if (rows.length <= params.endRow) {
            lastRow = data.count;
          }
          // call the success callback
          params.successCallback(rows, lastRow);
          setLoading(false);
        }
      });
    },
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.setGridOption("datasource", dataSource);
  }, []);

  useImperativeHandle(ref, () => ({
    refresh() {
      gridApi.setGridOption("datasource", dataSource);
    },
  }));

  return (
    <GridWrapper>
      <div style={containerStyle}>
        <div className="ag-theme-quartz" style={gridStyle}>
          <AgGridReact
            loading={loading}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowModelType={"infinite"}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={2}
            infiniteInitialRowCount={1}
            maxBlocksInCache={2}
            cacheBlockSize={100}
            pagination={true}
            paginationPageSize={100}
            paginationPageSizeSelector={[100]}
            getRowId={getRowId}
            onGridReady={onGridReady}
            loadingOverlayComponent={LoadingOverlay}
            loadingOverlayComponentParams={{ loadingMessage: "" }}
          />
        </div>
      </div>
    </GridWrapper>
  );
});

export function SimpleDropdownFilter({ model, onModelChange, options, text }) {
  const [isOpen, setIsOpen] = useState(false);
  const [textValue, setTextValue] = useState("");

  const doesFilterPass = () => true;

  const getModelAsString = useCallback(() => textValue, [model]);

  // register filter handlers with the grid
  useGridFilter({
    doesFilterPass,
    getModelAsString,
  });

  const onChange = (e, data) => {
    onModelChange(data.value);
    setTextValue(e.target.textContent);
  };

  return (
    <div
      style={{ height: isOpen ? "200px" : "fit-content", width: "fit-content" }}
    >
      <Dropdown
        className="ag-custom-component-popup"
        value={model}
        placeholder={text}
        onChange={onChange}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        search
        selection
        options={options}
      />
    </div>
  );
}

export function AsyncDropdownFilter({
  model,
  onModelChange,
  url,
  text,
  keyValue = "id",
  keyText = "title",
}) {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [textValue, setTextValue] = useState("");

  const doesFilterPass = () => true;

  const getModelAsString = useCallback(() => textValue, [model]);

  // register filter handlers with the grid
  useGridFilter({
    doesFilterPass,
    getModelAsString,
  });

  useEffect(() => {
    axios({
      method: "get",
      url,
    }).then((res) => {
      if (res.status === 200) {
        const data = res.data
          .filter((elem) => keyValue in elem && keyText in elem)
          .map((elem) => {
            return {
              value: elem[keyValue],
              text: elem[keyText],
            };
          });
        setOptions(data);
        setIsLoading(false);
      }
    });
  }, [url]);

  const onChange = (e, data) => {
    onModelChange(data.value);
    setTextValue(e.target.textContent);
  };

  return (
    <div
      style={{ height: isOpen ? "12rem" : "fit-content", width: "fit-content" }}
    >
      <Dropdown
        value={model}
        placeholder={text}
        onChange={onChange}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        search
        selection
        loading={isLoading}
        options={options}
      />
    </div>
  );
}

export default DataTable;
