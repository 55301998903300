import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, Grid, Container } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { withTranslation } from "react-i18next";

import LearnersTable from "./../components/LearnersTable";
import MainMenu from "./../components/MainMenu";

class Learners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      sessions: [],
    };

    this.closeModal = this.closeModal.bind(this);
  }
  closeModal() {
    this.props.changeModal();
  }
  render() {
    return (
      <Container>
        <MainMenu />
        <Modal open={this.state.open} className="modalHOTFIX">
          <Modal.Header>{this.props.t("Learners.replays")}</Modal.Header>
          <Modal.Content scrolling>
            <Grid celled>
              {this.state.sessions.map((data, i) => {
                return (
                  <Grid.Row key={i}>
                    <Grid.Column>
                      {data.exam_title} -{" "}
                      {moment(data.start_time).format("MMMM Do, YYYY")}
                      <NavLink to={`/replay/${data.session_guid}`}>
                        <Button circular icon="play" floated="right" />
                      </NavLink>
                    </Grid.Column>
                  </Grid.Row>
                );
              })}
            </Grid>
            <Modal.Actions>
              <Button
                primary
                onClick={() => {
                  this.setState({ open: false });
                }}
              >
                {this.props.t("general.close")}
              </Button>
            </Modal.Actions>
          </Modal.Content>
        </Modal>
        <LearnersTable />
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
    }),
    {}
  )(Learners)
);
