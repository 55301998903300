import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { addNotification } from "../reducers/notification";
const _ = require("underscore");

function RequireRBAC({ children, allowedRoles, t, kc, addNotification }) {
  //if the logged in user has one of the roles required to view this route.
  let intersect = _.intersection(allowedRoles, kc.realmAccess.roles);

  if (intersect.length > 0) {
    return children;
  } else {
    addNotification({
      message: t("App.accessDenied"),
      type: "error",
      timeout: true,
    });
    return <Navigate to="/" />;
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
    }),
    { addNotification }
  )(RequireRBAC)
);
