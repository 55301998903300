import React from "react";
import { withTranslation } from "react-i18next";

import BasicTable from "./Table/BasicTable";
import {
  ListRenderer,
  CheckMarkRenderer,
  DropdownRenderer,
} from "./Table/CustomComponents";

function BlockedDaysTable(props) {
  const columns = [
    {
      field: "name",
      headerName: props.t("general.name"),
      cellStyle: {
        verticalAlign: "middle",
        alignSelf: "center",
      },
      flex: 2,
      filter: true,
    },
    {
      field: "date",
      headerName: props.t("general.date"),
      valueGetter: (row) =>
        `${props.months[row.data.month - 1]} ${row.data.day}`,
      cellStyle: {
        verticalAlign: "middle",
        alignSelf: "center",
      },
      flex: 1,
      filter: true,
    },
    {
      field: "exams",
      headerName: props.t("general.exams"),
      cellRenderer: ListRenderer,
      valueGetter: (row) => {
        if (row.data.exams !== null && row.data.exams.length > 0) {
          return props.exams
            .filter((exam) => {
              return row.data.exams.includes(exam.id);
            })
            .map((exam) => ({ key: `exam_${exam.id}`, value: exam.title }));
        }
        return [{ key: `exam_all`, value: "All" }];
      },
      cellStyle: {
        verticalAlign: "middle",
        alignSelf: "center",
      },
      flex: 2,
      filter: true,
    },
    {
      field: "year",
      headerName: props.t("general.year"),
      cellStyle: {
        verticalAlign: "middle",
        alignSelf: "center",
      },
      flex: 1,
      filter: true,
    },
    {
      field: "every_year",
      headerName: props.t("BlockedDays.everyYear"),
      cellRenderer: CheckMarkRenderer,
      cellStyle: {
        textAlign: "center",
        verticalAlign: "middle",
        alignSelf: "flex-end",
      },
      flex: 1,
    },
    {
      field: "all_day",
      headerName: props.t("BlockedDays.allDay"),
      cellRenderer: CheckMarkRenderer,
      cellStyle: {
        textAlign: "center",
        verticalAlign: "middle",
        alignSelf: "flex-end",
      },
      flex: 1,
    },
    {
      headerName: props.t("general.actions"),
      cellRenderer: DropdownRenderer,
      cellRendererParams: {
        text: props.t("general.view"),
      },
      valueGetter: (row) => {
        return [
          {
            text: props.t("general.delete"),
            onClick: () => props.showDeleteBlockedDayModal(row.data.id),
          },
          {
            text: props.t("general.edit"),
            onClick: () => props.handleUpdateBlockedDay(row.data.id),
          },
        ];
      },
      cellStyle: {
        overflow: "inherit",
        textAlign: "center",
        alignSelf: "flex-end",
      },
      flex: 2,
    },
  ];

  return <BasicTable columns={columns} data={props.data} />;
}

export default withTranslation()(BlockedDaysTable);
