/**
 * Notifications can have the following values
 * Type [success,error,info]
 * message	string	The message string
 * title	string	The title string
 * timeOut	integer	The popup timeout in milliseconds
 * callback	function	A function that gets fired when the popup is clicked
 * priority	boolean	If true, the message gets inserted at the top
 * @type {{notifications: Array(type,message,title,timeOut,callback,priority), id: number}}
 */
const initState = {
  notifications: [],
  id: 1,
};

const ADD_NOTIFICATION = "ADD_NOTIFICATION";
const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

export const addNotification = (notification) => ({
  type: ADD_NOTIFICATION,
  payload: notification,
});
export const dismissNotification = (notification) => ({
  type: DISMISS_NOTIFICATION,
  payload: notification,
});

export default (state = initState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      action.payload.id = state.id;
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: action.payload.id,
            type: action.payload.type || "info",
            message: action.payload.message || "Please give me a message",
            title: action.payload.title || "Error",
            link: action.payload.link || null,
            callback: action.payload.callback || null,
            priority: action.payload.priority || true,
            timeout: action.payload.timeout || false,
          },
        ],
        id: state.id + 1,
      };
    case DISMISS_NOTIFICATION:
      let notifications = state.notifications.filter((data) => {
        if (data.id === action.payload) {
          return false;
        }
        return true;
      });
      return { ...state, notifications: notifications };
    default:
      return state;
  }
};
