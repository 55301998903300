import React, { Component } from "react";
import { connect } from "react-redux";
import parseTimezone from "../../lib/helperServices";
import {
  Container,
  Header,
  Button,
  Grid,
  Dropdown,
  Segment,
  Form,
  Icon,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import ExamDetails from "./ExamDetails";
import {
  fetchBookingTimesForDate,
  fetchBookingsForUser,
  fetchNextAvailableBooking,
} from "../../reducers/exam";
import { withTranslation } from "react-i18next";
import { getBlockedDaysForSixMonths } from "./../../reducers/booking";
import {
  addDays,
  addMonths,
  addHours,
  format,
  getISODay,
  isBefore,
  parse,
} from "date-fns";
import { TZDate } from "@date-fns/tz";

const DAYS_OF_WEEKS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

class SelectDates extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    let times = [];

    this.minDate = new Date();
    this.maxDate = addMonths(new Date(), 5);

    this.timeFormat =
      localStorage.getItem("lang") === "en" ? "hh:mm a" : "HH:mm";
    if (
      this.props.formData.exam.available_from_time &&
      this.props.formData.exam.available_to_time
    ) {
      let available_from_time = parse(
        this.props.formData.exam.available_from_time,
        "HH:mm:ss",
        new Date()
      );

      let available_to_time = parse(
        this.props.formData.exam.available_to_time,
        "HH:mm:ss",
        new Date()
      );

      while (isBefore(available_from_time, available_to_time)) {
        times.push({
          text: format(available_from_time, this.timeFormat),
          value: format(available_from_time, "HH:mm"),
        });
        available_from_time = addHours(available_from_time, 1);
      }
    } else {
      times = [
        { text: "9:00 AM", value: "9:00" },
        { text: "10:00 AM", value: "10:00" },
        { text: "11:00 AM", value: "11:00" },
        { text: "12:00 PM", value: "12:00" },
        { text: "1:00 PM", value: "13:00" },
        { text: "2:00 PM", value: "14:00" },
        { text: "3:00 PM", value: "15:00" },
      ];
    }

    this.timezone = parseTimezone(
      this.props.kc.tokenParsed.timezone,
      this.props.kc.tokenParsed.province
    );
    let timezone_array = this.props.kc.tokenParsed.timezone.split("");

    this.available_times = times.map((data) => {
      const date = new TZDate(
        parse(data.value, "HH:mm", new Date()),
        this.timezone
      );
      return {
        value: data.value,
        text: `${format(date, this.timeFormat)} (${timezone_array[0]}${
          timezone_array[2]
        })`,
      };
    });

    this.state = {
      examDate: null,
      examTime: null,
      available_times:
        this.props.formData.available_times || this.available_times,
      excludedDates: [],
      datePickerLoading: true,
      bookLimit: "",
    };
  }
  async componentDidMount() {
    let timeFormat =
      localStorage.getItem("lang") === "en" ? "hh:mm a" : "HH:mm";

    const excludedDates = this.getExcludedDates();
    this.setState({ excludedDates, datePickerLoading: false });
    this.props.getBlockedDaysForSixMonths(this.props.formData.exam.id);
    this.props
      .fetchBookingsForUser(
        this.props.formData.exam.id,
        this.props.kc.tokenParsed.sub
      )
      .then((limit) => {
        this.setState({ bookLimit: limit });
      });
    let nextAvailable = await this.props.fetchNextAvailableBooking(
      this.props.formData.exam.id,
      this.props.kc.tokenParsed.sub
    );

    if (nextAvailable) {
      const nextBooking = new TZDate(nextAvailable, this.timezone);
      this.setState({
        examDate: nextBooking,
        examTime: format(nextBooking, timeFormat),
      });

      this.props
        .fetchBookingTimesForDate(
          this.props.formData.exam.id,
          format(nextBooking, "yyyy-MM-dd")
        )
        .then((times) => {
          const mappedTimes = times.map((time) =>
            format(parse(time, "HH:mm", new Date()), this.timeFormat)
          );
          const availableTimes = this.available_times.map((available) => {
            return Object.assign({}, available, {
              disabled: mappedTimes.indexOf(available.value) !== -1,
              text:
                mappedTimes.indexOf(available.value) !== -1
                  ? `${available.text} - ${this.props.t(
                      "SelectDates.fullyBooked"
                    )}`
                  : available.text,
            });
          });
          this.setState({ available_times: availableTimes });
        });
    }
  }
  getExcludedDates() {
    let daysAvailable = JSON.parse(this.props.formData.exam.available_days);
    let excludeDates = [];
    if (daysAvailable && daysAvailable.length > 0) {
      let days = [];
      for (let day of DAYS_OF_WEEKS) {
        if (daysAvailable.includes(day)) {
          days.push(DAYS_OF_WEEKS.indexOf(day) + 1);
        }
      }

      let date = new Date();
      const end = addMonths(new Date(), 5);
      while (isBefore(date, end)) {
        if (!days.includes(getISODay(date))) {
          excludeDates.push(new Date(date.getTime()));
        }

        date = addDays(date, 1);
      }

      excludeDates.push(new Date());
      excludeDates.push(addDays(new Date(), 1));
    }

    return excludeDates;
  }
  getBlockedDaysForMonth() {
    const blockedDays = [];
    for (const blockedDay of this.props.blockedDaysInMonth) {
      const year = blockedDay.every_year
        ? new Date().getFullYear()
        : blockedDay.year;
      if (blockedDay.all_day === true) {
        blockedDays.push(new Date(year, blockedDay.month - 1, blockedDay.day));
        // Adding next year as well since date range can extend into the next year
        blockedDays.push(
          new Date(year + 1, blockedDay.month - 1, blockedDay.day)
        );
      }
    }
    return blockedDays;
  }
  updateExamDate = (date) => {
    if (date) {
      this.props
        .fetchBookingTimesForDate(
          this.props.formData.exam.id,
          format(date, "yyyy-MM-dd")
        )
        .then((times) => {
          const mappedTimes = times.map((time) =>
            format(parse(time, "HH:mm", new Date()), this.timeFormat)
          );
          let availableTimes = this.available_times.map((available) => {
            return Object.assign({}, available, {
              disabled: mappedTimes.indexOf(available.value) !== -1,
              text:
                mappedTimes.indexOf(available.value) !== -1
                  ? `${available.text} - ${this.props.t(
                      "SelectDates.fullyBooked"
                    )}`
                  : available.text,
            });
          });

          this.setState({
            available_times: availableTimes,
            examDate: date,
            examTime: null,
          });
        });
    } else {
      this.setState({
        examDate: date,
        examTime: null,
      });
    }
  };

  updateExamTime = (e, { text, value }) => {
    const d = `${format(
      new Date(this.state.examDate.getTime()),
      "yyyy-MM-dd"
    )} ${value}`;
    const examDate = parse(d, `yyyy-MM-dd HH:mm`, new Date());
    this.setState({
      examTime: value,
      examDate: examDate,
    });
  };
  onSubmit = (event, data) => {
    event.preventDefault();
    if (this.state.examDate && this.state.examTime) {
      this.props.onForward({
        examDate: this.state.examDate,
        examTime: this.state.examTime,
      });
    }
  };
  render() {
    let formData = this.props.formData;
    let exam = formData.exam;
    return (
      <Container>
        {this.state.bookLimit ? (
          <Form>
            <Form.Field>
              <Segment>
                <Header>{this.props.t("general.note")}</Header>
                {this.state.bookLimit}
              </Segment>
              <Button
                onClick={() => {
                  this.setState({
                    examDate: null,
                    examTime: null,
                  });
                  this.props.onBackward();
                }}
                negative
                floated="left"
              >
                <Button.Content visible>
                  <Icon name="left arrow" />
                  {this.props.t("general.previous")}
                </Button.Content>
              </Button>
            </Form.Field>
          </Form>
        ) : (
          <Form>
            <ExamDetails
              step={this.props.step}
              examTitle={exam.title}
              duration={exam.duration}
              cost={exam.cost || 0.0}
            />
            <Form.Field>
              <Segment.Group horizontal>
                <Segment>
                  <Header>{this.props.t("general.nextBooking")}</Header>
                  <Grid columns={1} relaxed="very">
                    <Grid.Column>
                      <p align="center" style={{ padding: 10 }}>
                        {this.state.examDate === null
                          ? ""
                          : format(
                              this.state.examDate,
                              "MMMM dd,yyyy " + this.timeFormat
                            )}
                      </p>
                    </Grid.Column>
                  </Grid>
                </Segment>
                <Segment>
                  <Header>{this.props.t("general.date")}</Header>
                  <DatePicker
                    disabled={this.state.datePickerLoading}
                    selected={this.state.examDate}
                    onChange={this.updateExamDate}
                    minDate={this.minDate}
                    maxDate={this.maxDate}
                    excludeDates={[
                      ...this.state.excludedDates,
                      ...this.getBlockedDaysForMonth(),
                    ]}
                    disabledKeyboardNavigation={true}
                  />
                </Segment>
                <Segment>
                  <Header>{this.props.t("general.time")}</Header>
                  <Dropdown
                    fluid
                    disabled={this.state.examDate === null}
                    style={{ marginRight: 10 }}
                    onChange={this.updateExamTime}
                    value={this.state.examTime}
                    placeholder={
                      this.state.examDate
                        ? this.props.t("general.time")
                        : this.props.t("SelectDates.selectDate")
                    }
                    search
                    selection
                    options={this.state.available_times}
                  />
                </Segment>
              </Segment.Group>
            </Form.Field>
            <Segment>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Button
                      onClick={() => {
                        this.setState({
                          examDate: null,
                          examTime: null,
                        });
                        this.props.onBackward();
                      }}
                      negative
                      floated="left"
                    >
                      <Button.Content visible>
                        <Icon name="left arrow" />
                        {this.props.t("general.previous")}
                      </Button.Content>
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      onClick={this.onSubmit}
                      disabled={
                        this.state.examDate == null ||
                        this.state.examTime == null
                      }
                      positive
                      floated="right"
                    >
                      <Button.Content visible>
                        {this.props.t("general.next")}
                        <Icon name="right arrow" />
                      </Button.Content>
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Form>
        )}
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam,
      blockedDaysInMonth: state.bookings.blockedDaysInMonth,
      userBookings: state.bookings.userBookings,
      nextBooking: state.nextBooking,
    }),
    {
      fetchBookingTimesForDate,
      getBlockedDaysForSixMonths,
      fetchBookingsForUser,
      fetchNextAvailableBooking,
    }
  )(SelectDates)
);
