import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Checkbox,
  Header,
  Button,
  Grid,
  Segment,
  Form,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { rescheduleBooking, updateDeleteModal } from "../../reducers/booking";
import ExamDetails from "./ExamDetails";
import { withTranslation } from "react-i18next";
import { addMonths, setHours, setMinutes } from "date-fns";
import { TZDate } from "@date-fns/tz";
import parseTimezone from "../../lib/helperServices";

const MIN_TIME = 7;
const MAX_TIME = 23;

class RescheduleDates extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    const timezone = parseTimezone(
      this.props.kc.tokenParsed.timezone,
      this.props.kc.tokenParsed.province
    );
    const examDate = new TZDate(this.props.booking.booking_date, timezone);

    this.minDate = new Date();
    this.maxDate = addMonths(new Date(), 5);
    this.minTime = setHours(setMinutes(new Date(), 0), MIN_TIME);
    this.maxTime = setHours(setMinutes(new Date(), 0), MAX_TIME);

    this.state = {
      examDate,
      id: this.props.booking.id,
      sendRescheduleEmail: false,
    };
  }

  updateExamDate = (examDate) =>
    this.setState({
      examDate,
    });

  handleUpdateCheckbox() {
    this.setState({
      sendRescheduleEmail: !this.state.sendRescheduleEmail,
    });
  }

  render() {
    const exam = this.props.booking.exam;
    return (
      <Container>
        <Form>
          <ExamDetails
            step={this.props.step}
            examTitle={exam.title}
            duration={exam.duration || "-"}
            cost={exam.cost || 0.0}
          />
          <Form.Field>
            <Segment>
              <Header>Date & Time</Header>
              <DatePicker
                selected={this.state.examDate}
                showTimeSelect
                timeIntervals={60}
                dateFormat="MM/dd/yyyy - hh:mm a"
                onChange={this.updateExamDate}
                minDate={this.minDate}
                maxDate={this.maxDate}
                minTime={this.minTime}
                maxTime={this.maxTime}
              />
            </Segment>
            <Form.Field></Form.Field>
          </Form.Field>
          <Form.Field>
            <Segment>
              <Grid.Column width="5">
                <span style={{ paddingRight: "50px" }}>
                  {this.props.t("RescheduleDates.sendUserNotification")}
                </span>
                <Checkbox
                  onClick={() => this.handleUpdateCheckbox()}
                  label=" Yes"
                  checked={this.state.sendRescheduleEmail}
                />
              </Grid.Column>
            </Segment>
          </Form.Field>
          <Button
            style={{ marginBottom: "20px" }}
            onClick={() =>
              this.props.updateBooking({
                ...this.state,
                booking: this.props.booking,
              })
            }
            type="submit"
            floated="right"
            color="green"
          >
            Submit
          </Button>
          <Button
            style={{ marginBottom: "20px" }}
            onClick={() => this.props.close()}
            floated="right"
            color="red"
          >
            Cancel
          </Button>
        </Form>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam,
    }),
    { rescheduleBooking, updateDeleteModal }
  )(RescheduleDates)
);
