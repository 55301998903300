import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Segment,
  Table,
  Divider,
  Button,
  Grid,
  Icon,
} from "semantic-ui-react";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";

class Receipt extends Component {
  render() {
    let formData = this.props.formData;
    let bookingData = formData.booking.data;
    let exam = formData.exam;
    let taxes;
    let total;
    let discount;

    if (formData.coupon) {
      discount = exam.cost - formData.examPrice;
      taxes = (formData.examPrice * this.props.bookings.taxRate) / 100;
      total = formData.examPrice + taxes;
    } else {
      taxes = (exam.cost * this.props.bookings.taxRate) / 100;
      total = exam.cost + taxes;
      discount = 0;
    }

    const cost = bookingData.requires_payment ? exam.cost : 0;
    return (
      <Container>
        <Segment>
          <Header>{this.props.t("Receipt.header")}</Header>
          <p>{this.props.t("Receipt.confirmEligibilityMessage")}</p>
          <br />
          <p>{this.props.t("Receipt.downloadEproctorClientMessage")}</p>
          <br />
          <p>{this.props.t("Receipt.questionsMessage")}</p>
          <Divider />
          <Header>
            {this.props.t("general.exam")}: {exam.title}
          </Header>
          <Header>{this.props.t("Receipt.bookingDetails")}</Header>
          <Header>{this.props.t("Receipt.orderDetails")}</Header>
          <Table striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{this.props.t("general.duration")}</Header>
                </Table.Cell>
                <Table.Cell>{exam.duration} hours</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{this.props.t("ExamDetails.price")}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Trans
                    i18nKey="general.formattedPrice"
                    defaults="{{price}}"
                    values={{ price: cost }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{this.props.t("ExamDetails.notes")}</Header>
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{this.props.t("ExamDetails.datePending")}</Header>
                </Table.Cell>
                <Table.Cell>
                  {format(formData.examDate, "MMMM do, yyyy")}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>
                    {this.props.t("ExamDetails.creditCardNumber")}
                  </Header>
                </Table.Cell>
                <Table.Cell>{`${formData.pan}` || "-"}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>
                    {this.props.t("ExamDetails.creditCardExpiration")}
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  {bookingData.requires_payment
                    ? `${formData.expMonth}-${formData.expYear}` || "-"
                    : "N/A"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Header>{this.props.t("PaymentDetails.header")}:</Header>
          <Table striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{this.props.t("ExamDetails.examPrice")}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Trans
                    i18nKey="general.formattedPrice"
                    defaults="{{price}}"
                    values={{ price: cost }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{this.props.t("ExamDetails.tax")}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Trans
                    i18nKey="general.formattedPrice"
                    defaults="{{price}}"
                    values={{ price: taxes.toFixed(2) }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{this.props.t("ExamDetails.discount")}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Trans
                    i18nKey="general.formattedPrice"
                    defaults="{{price}}"
                    values={{ price: discount.toFixed(2) }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{this.props.t("ExamDetails.total")}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Trans
                    i18nKey="general.formattedPrice"
                    defaults="{{price}}"
                    values={{ price: total.toFixed(2) }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Grid>
            <Grid.Row centered>
              <NavLink to="/">
                <Button size="big" color="green">
                  {this.props.t("general.myDashboard")}
                  <Icon name="arrow right" />
                </Button>
              </NavLink>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam,
      bookings: state.bookings,
    }),
    {}
  )(Receipt)
);
