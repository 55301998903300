import React, { Component } from "react";
import {
  Button,
  Modal,
  Dropdown,
  Form,
  Grid,
  Input,
  Checkbox,
  Message,
  Header,
} from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import BlockedDaysTable from "./BlockedDaysTable";
import {
  storeBlockedDay,
  deleteBlockedDay,
  updateBlockedToTime,
  updateBlockedFromTime,
  setIsUpdating,
  updateExistingBlockedDay,
} from "./../reducers/booking";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { fetchActiveExams } from "./../reducers/exam";

class BlockedDays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      showDeleteModal: false,
      showAddModal: false,
      name: "",
      date: null,
      year: "",
      everyYear: true,
      allDay: false,
      deletingId: null,
      error: false,
      excludedToTimes: [],
      excludedFromTimes: [],
      examArray: {},
      blockedExams: {},
      submissionError: false,
      errorMessage: "",
    };
    this.months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.handleAllDay = this.handleAllDay.bind(this);
    this.handleBlockedFromTime = this.handleBlockedFromTime.bind(this);
    this.handleBlockedToTime = this.handleBlockedToTime.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.showDeleteBlockedDayModal = this.showDeleteBlockedDayModal.bind(this);
    this.handleUpdateBlockedDay = this.handleUpdateBlockedDay.bind(this);
  }
  componentDidMount() {
    this.props.fetchActiveExams();
  }
  updateBlockedExams(value) {
    this.props.updateBlockedExams(value);
  }
  handleAddDays() {
    this.setState({
      examArray: this.props.exams.allExams.map((exam, index) => {
        return {
          text: exam.title,
          value: exam.id,
        };
      }),
      showAddModal: true,
    });
    this.setState({
      id: "",
      name: "",
      date: "",
      year: "",
      everyYear: true,
      allDay: false,
      blockedExams: {},
      error: false,
      submissionError: false,
      errorMessage: "",
    });
    this.props.updateBlockedToTime("");
    this.props.updateBlockedFromTime("");
    this.props.setIsUpdating(false);
  }
  handleAllDay(data) {
    if (data.checked) {
      this.props.updateBlockedToTime("");
      this.props.updateBlockedFromTime("");
      this.setState({ allDay: true });
    } else {
      this.setState({ allDay: false });
    }
  }
  submitForm = (event, data) => {
    event.preventDefault();
    this.setState({ error: false });
    if (this.state.name && this.state.date) {
      if (this.props.bookings.isUpdating) {
        this.props
          .updateExistingBlockedDay({
            id: this.state.id,
            name: this.state.name,
            date: this.state.date,
            exams: this.state.blockedExams,
            year: !this.state.everyYear
              ? moment(this.state.date).format("YYYY")
              : null,
            everyYear: this.state.everyYear,
            allDay: this.state.allDay,
            fromTime: this.props.bookings.blocked_from_time
              ? moment(this.props.bookings.blocked_from_time).format(
                  "h:mm:ss a"
                )
              : null,
            toTime: this.props.bookings.blocked_to_time
              ? moment(this.props.bookings.blocked_to_time).format("h:mm:ss a")
              : null,
          })
          .then(() => {
            this.props.updateBlockedToTime("");
            this.props.updateBlockedFromTime("");
            this.setState({
              showAddModal: false,
              name: "",
              date: "",
              everYear: true,
            });
          })
          .catch((error) => {
            this.setState({
              submissionError: true,
              errorMessage: error.response.data,
            });
          });
      } else {
        this.props
          .storeBlockedDay({
            name: this.state.name,
            date: this.state.date,
            exams: this.state.blockedExams,
            everyYear: this.state.everyYear,
            allDay: this.state.allDay,
            fromTime: this.props.bookings.blocked_from_time
              ? moment(this.props.bookings.blocked_from_time).format(
                  "h:mm:ss a"
                )
              : null,
            toTime: this.props.bookings.blocked_to_time
              ? moment(this.props.bookings.blocked_to_time).format("h:mm:ss a")
              : null,
          })
          .then(() => {
            this.props.updateBlockedToTime("");
            this.props.updateBlockedFromTime("");
            this.setState({
              showAddModal: false,
              name: "",
              date: "",
              everYear: true,
            });
          })
          .catch((error) => {
            this.setState({
              submissionError: true,
              errorMessage: error.response.data,
            });
          });
      }
    } else {
      this.setState({ error: true });
    }
  };
  handleBlockedFromTime(time) {
    this.props.updateBlockedFromTime(time);
    let startTime = moment("12:00 am", "h:mm a");
    let selected = moment(time.format("h:mm a"), "h:mm a");
    let excludedTimes = [];

    while (selected.isAfter(startTime) || selected.isSame(startTime)) {
      excludedTimes.push(moment(selected.format("h:mm a"), "h:mm a"));
      selected.subtract("1", "hour");
    }
    this.setState({ excludedToTimes: excludedTimes });
  }
  handleBlockedToTime(time) {
    this.props.updateBlockedToTime(time);
    let endTime = moment("11:00 pm", "h:mm a");
    let selected = moment(time.format("h:mm a"), "h:mm a");
    let excludedTimes = [];

    while (selected.isBefore(endTime) || selected.isSame(endTime)) {
      excludedTimes.push(moment(selected.format("h:mm a"), "h:mm a"));
      selected.add("1", "hour");
    }
    this.setState({ excludedFromTimes: excludedTimes });
  }
  handleDelete() {
    this.props.deleteBlockedDay(this.state.deletingId);
    this.setState({
      showDeleteModal: false,
      deletingId: null,
    });
  }
  showDeleteBlockedDayModal(deletingId) {
    this.setState({
      showDeleteModal: true,
      deletingId,
    });
  }
  handleUpdateBlockedDay(id) {
    this.setState({
      examArray: this.props.exams.allExams.map((exam, index) => {
        return {
          text: exam.title,
          value: exam.id,
        };
      }),
      showAddModal: true,
    });
    let year;
    let date;
    let time;
    let blockedDay = this.props.bookings.blockedDays.filter(
      (data) => data.id === id
    );
    blockedDay = blockedDay[0];
    if (blockedDay.from_time) {
      this.props.updateBlockedFromTime(
        moment(blockedDay.from_time, "HH:mm:ss")
      );
    }
    if (blockedDay.to_time) {
      this.props.updateBlockedToTime(moment(blockedDay.to_time, "HH:mm:ss"));
    }
    this.setState({ blockedExams: blockedDay.exams });
    this.setState({ name: blockedDay.name });
    this.setState({ everyYear: blockedDay.every_year });
    this.setState({ allDay: blockedDay.all_day });
    this.setState({ id: blockedDay.id });

    if (blockedDay.every_year) {
      year = new Date().getFullYear();
    } else {
      year = blockedDay.year;
    }
    date = year + "-" + blockedDay.month + "-" + blockedDay.day;
    this.setState({ date: moment(date, "YYYY-MM-DD") });
    this.props.setIsUpdating(true);
  }
  render() {
    return (
      <div>
        <Grid style={{ paddingBottom: "25px" }}>
          <Grid.Row>
            <Grid.Column>
              <Header style={{ display: "inline" }}>
                {this.props.t("BlockedDays.header")}
              </Header>
              <Button
                floated="right"
                color="green"
                onClick={() => this.handleAddDays()}
              >
                {this.props.t("BlockedDays.addBlockedDay")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          size="small"
          open={this.state.showDeleteModal}
          className="modalHOTFIX"
          closeIcon
          onClose={() => this.setState({ showDeleteModal: false })}
        >
          <Modal.Header>{this.props.t("general.delete")}</Modal.Header>
          <Modal.Content>
            <p>{this.props.t("BlockedDays.confirmDelete")}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => this.setState({ showDeleteModal: false })}
            >
              {this.props.t("general.no")}
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content={this.props.t("general.yes")}
              onClick={this.handleDelete}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          size="small"
          open={this.state.showAddModal}
          className="modalHOTFIX"
          closeIcon
          onClose={() => this.setState({ showAddModal: false })}
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            {this.props.t("BlockedDays.addBlockedDay")}
          </Modal.Header>
          <Modal.Content>
            {this.state.error ? (
              <Message negative>
                <Message.Header>{this.props.t("general.error")}</Message.Header>
                <p>{this.props.t("BlockedDays.missingFieldsError")}</p>
              </Message>
            ) : null}
            {this.state.submissionError ? (
              <Message
                negative
                onDismiss={() =>
                  this.setState({
                    submissionError: false,
                    errorMessage: "",
                  })
                }
              >
                <Message.Header>{this.props.t("general.error")}</Message.Header>
                <p>{this.state.errorMessage}</p>
              </Message>
            ) : null}
            <Form>
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Form.Field>
                      <label>{this.props.t("general.exams")}</label>
                      <Dropdown
                        //value={this.state.blockedExams}
                        options={Array.from(this.state.examArray)}
                        onChange={(e, data) =>
                          this.setState({ blockedExams: data.value })
                        }
                        placeholder={this.props.t("CouponTable.selectExams")}
                        fluid
                        multiple
                        selection
                      />
                    </Form.Field>
                    <Form.Field
                      id="form-input-control-name"
                      name="name"
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      control={Input}
                      label={this.props.t("general.name")}
                    />
                    <Form.Field>
                      <label>{this.props.t("general.date")}</label>
                      <DatePicker
                        selected={this.state.date}
                        onChange={(date) => this.setState({ date })}
                      />
                    </Form.Field>

                    <Checkbox
                      onChange={(e, data) =>
                        this.setState({ everyYear: data.checked })
                      }
                      label={this.props.t("BlockedDays.everyYearLabel")}
                      checked={this.state.everyYear}
                    />
                    <Form.Field>
                      <Checkbox
                        onChange={(e, data) => this.handleAllDay(data)}
                        label={this.props.t("BlockedDays.allDayLabel")}
                        checked={this.state.allDay}
                      />
                    </Form.Field>
                    {!this.state.allDay ? (
                      <Form.Field>
                        <label>{this.props.t("BlockedDays.times")}</label>
                        <Form.Group inline>
                          <label>{this.props.t("Exam.from")}:</label>
                          <DatePicker
                            selected={
                              this.props.bookings.blocked_from_time === ""
                                ? null
                                : this.props.bookings.blocked_from_time
                            }
                            onChange={(time) =>
                              this.handleBlockedFromTime(time)
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            dateFormat="h:mm a"
                            timeCaption="Time"
                            excludeTimes={this.state.excludedFromTimes}
                          />
                          <label>{this.props.t("Exam.to")}:</label>
                          <DatePicker
                            selected={
                              this.props.bookings.blocked_to_time === ""
                                ? null
                                : this.props.bookings.blocked_to_time
                            }
                            onChange={(time) => this.handleBlockedToTime(time)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            dateFormat="h:mm a"
                            timeCaption="Time"
                            excludeTimes={this.state.excludedToTimes}
                          />
                        </Form.Group>
                      </Form.Field>
                    ) : null}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      onClick={() => this.setState({ showAddModal: false })}
                      color="red"
                      floated="right"
                    >
                      {this.props.t("general.cancel")}
                    </Button>
                    <Button
                      onClick={(e) => this.submitForm(e)}
                      color="green"
                      floated="right"
                    >
                      {this.props.t("general.save")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>
        <BlockedDaysTable
          months={this.months}
          data={this.props.bookings.blockedDays}
          showDeleteBlockedDayModal={this.showDeleteBlockedDayModal}
          handleUpdateBlockedDay={this.handleUpdateBlockedDay}
          exams={this.props.exams.allExams}
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      bookings: state.bookings,
      exams: state.exam,
    }),
    {
      storeBlockedDay,
      deleteBlockedDay,
      fetchActiveExams,
      updateBlockedToTime,
      updateBlockedFromTime,
      setIsUpdating,
      updateExistingBlockedDay,
    }
  )(BlockedDays)
);
