import React from "react";
import { Button, Modal, Header, Form, Input } from "semantic-ui-react";
import {
  updateUrl,
  updateSocketForUrlPush,
  updatePushModal,
} from "../reducers/session";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class PushUrlModal extends React.Component {
  constructor(props) {
    super(props);
    this.updateUrl = this.updateUrl.bind(this);
  }

  updateUrl(e) {
    this.props.updateUrl(e.target.value);
  }

  render() {
    return (
      <Modal
        open={this.props.showPushModal}
        closeIcon
        onClose={() => {
          this.props.updatePushModal();
        }}
      >
        <Modal.Header>
          {this.props.t("ProctoringSession.pushExamUrl")}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>{this.props.t("ProctoringSession.pushExam")}</Header>
            <Form>
              <Form.Field>
                <label>{this.props.t("ProctoringSession.url")}</label>
                <Input onChange={this.updateUrl} />
              </Form.Field>
              <Button onClick={this.props.pushUrl}>
                {this.props.t("general.enter")}
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      url: state.session.url,
      socketForUrlPush: state.session.socketForUrlPush,
      showPushModal: state.session.showPushModal,
    }),
    { updateUrl, updateSocketForUrlPush, updatePushModal }
  )(PushUrlModal)
);
