import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Progress, Header } from "semantic-ui-react";
import { fetchExams } from "../reducers/exam";
import SelectExam from "../components/Bookings/SelectExam";
import SelectDates from "../components/Bookings/SelectDates";
import PaymentDetails from "../components/Bookings/PaymentDetails";
import ConfirmBooking from "../components/Bookings/ConfirmBooking";
import Receipt from "../components/Bookings/Receipt";
import { withTranslation } from "react-i18next";

class BookExam extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);

    this.state = {
      page: 1,
      percentage: 0,
      formData: {},
    };
  }
  nextPage(formData) {
    this.setState({
      page: this.state.page + 1,
      percentage: this.state.percentage + 25,
      formData: {
        ...this.state.formData,
        ...formData,
      },
    });
  }

  previousPage(formData) {
    let page =
      this.state.page -
      (this.state.page === 4 && !this.state.formData.exam.requires_payment
        ? 2
        : 1);
    this.setState({
      page: page,
      percentage: this.state.percentage - 25,
      formData: {
        ...this.state.formData,
        ...formData,
      },
    });
  }

  showStep = () => {
    switch (this.state.page) {
      case 1:
        return (
          <SelectExam
            onForward={this.nextPage}
            formData={this.state.formData}
            step={this.state.page}
          />
        );
      case 2:
        return (
          <SelectDates
            onBackward={this.previousPage}
            onForward={this.nextPage}
            formData={this.state.formData}
            step={this.state.page}
          />
        );
      case 3:
        return (
          <PaymentDetails
            onBackward={this.previousPage}
            onForward={this.nextPage}
            formData={this.state.formData}
            step={this.state.page}
          />
        );
      case 4:
        return (
          <ConfirmBooking
            onBackward={this.previousPage}
            onForward={this.nextPage}
            formData={this.state.formData}
            step={this.state.page}
          />
        );
      case 5:
        return (
          <Receipt
            onBackward={this.previousPage}
            onForward={this.nextPage}
            formData={this.state.formData}
            step={this.state.page}
          />
        );
      default:
        return (
          <SelectExam
            onForward={this.nextPage}
            formData={this.state.formData}
            step={this.state.page}
          />
        );
    }
  };

  render() {
    return (
      <Container>
        <Header>
          {this.props.t("Emails.BookingRequest.approvalTimeFrame")}
        </Header>
        <Progress percent={this.state.percentage} success />
        {this.showStep()}
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam,
    }),
    {
      fetchExams,
    }
  )(BookExam)
);
