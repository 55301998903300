import { Dropdown } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

export function DropdownRenderer(params) {
  if (params.value) {
    return (
      <Dropdown
        style={{ background: "#0089a8", color: "white" }}
        button
        floating
        direction="left"
        text={params.text}
      >
        <Dropdown.Menu>
          {params.value.map((item, i) => (
            <Dropdown.Item
              key={i}
              style={{ zIndex: 2000 }}
              onClick={item.onClick}
            >
              {item.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export function LinkRenderer(params) {
  return <NavLink to={params.value}>{params.text}</NavLink>;
}

export function ListRenderer(params) {
  const list = params.value.map((item) => {
    return <li key={item.key}>{item.value}</li>;
  });

  return <ul className="exams-list">{list}</ul>;
}

export function CheckMarkRenderer(params) {
  if (params.value) {
    return (
      <i
        style={{ color: "#5cb84b", fontSize: "26px" }}
        className="check icon"
      ></i>
    );
  } else {
    return (
      <i
        style={{ color: "#db4737", fontSize: "26px" }}
        className="close icon"
      ></i>
    );
  }
}

export function ColorTextRenderer(params) {
  return <span style={{ color: params.color }}>{params.value}</span>;
}
