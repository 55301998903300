import React from "react";
import { Button } from "semantic-ui-react";

function MuteButton(props) {
  return (
    <Button
      title="Mute"
      circular
      icon={props.muted ? "mute" : "unmute"}
      size={props.size}
      onClick={() => props.onClick(props.user_id)}
    />
  );
}

export default MuteButton;
