import React from "react";
import { Feed } from "semantic-ui-react";
import SingleNote from "../components/SingleNote";
import "../reducers/session";
import { connect } from "react-redux";

class NoteLog extends React.Component {
  render() {
    return (
      <Feed style={{ height: 100, overflowY: "scroll" }}>
        {this.props.notes.map((data, i) => {
          if (data.note.session_id === this.props.maximizedUser.id) {
            return (
              <SingleNote
                key={i}
                timestamp={data.note.timestamp}
                note={data.note.event}
                user={this.props.maximizedUser.user}
              />
            );
          } else {
            return null;
          }
        })}
      </Feed>
    );
  }
}

export default connect(
  (state) => ({
    notes: state.session.notes,
    maximizedUser: state.session.maximizedUser,
  }),
  {}
)(NoteLog);
