export default function parseTimezone(timezone, province = "") {
  switch (timezone) {
    case "NST":
    case "NT":
    case "NDT":
      return "Canada/Newfoundland";
    case "AST":
    case "ADT":
    case "AT":
      return "Canada/Atlantic";
    case "EST":
    case "EDT":
    case "ET":
      return "Canada/Eastern";
    case "CT":
    case "CDT":
    case "CST":
      if (province === "SK") {
        return "Canada/Saskatchewan";
      } else {
        return "Canada/Central";
      }
    case "MT":
    case "MDT":
    case "MST":
      return "Canada/Mountain";
    case "PT":
    case "PDT":
    case "PST":
      return "Canada/Pacific";
    default:
      return "Canada/Eastern";
  }
}
