import React, { useState, useRef, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { addDays, format } from "date-fns";

import RescheduleExamModal from "./RescheduleExamModal";
import ConfirmationModal from "./ConfirmationModal";
import {
  getBookingByBookingId,
  cancel,
  deleteExam,
} from "../lib/bookingServices";

import DataTable, {
  SimpleDropdownFilter,
  AsyncDropdownFilter,
} from "./Table/DataTable";
import { DropdownRenderer } from "./Table/CustomComponents";

function StatusRenderer(params) {
  const status = params.value.status;
  const color = ["approved", "completed"].includes(status) ? "green" : "red";
  return (
    <React.Fragment>
      <span style={{ color: status === "pending" ? null : color }}>
        {params.text}
      </span>
      {params.value.file_upload_required ? (
        <a href="#" style={{ color: "black" }} onClick={params.value.onClick}>
          <Icon name="file outline" size="large" />
        </a>
      ) : null}
    </React.Fragment>
  );
}

const textFilterParams = {
  filterOptions: ["contains"],
  trimInput: true,
  debounceMs: 1000,
};

function getFilterParams(filterValues) {
  const params = {
    username: filterValues.username?.filter,
    firstName: filterValues.first_name?.filter,
    lastName: filterValues.last_name?.filter,
    status: filterValues.approved,
    from: filterValues.booking_date?.dateFrom,
    to: filterValues.booking_date?.dateTo,
    exam: filterValues.exam,
  };

  if (filterValues.booking_date) {
    // Server expects date in 'to' with 'from' null for filtering for dates before
    if (filterValues.booking_date.type === "lessThan") {
      params.to = params.from;
      params.from = null;
    } else if (filterValues.booking_date.type === "equals") {
      // If searching for specific date, just do a ranged filter which is exclusive of 'to' date
      params.to = addDays(new Date(params.from), 1);
    }
  }

  return params;
}

const RescheduleExamTable = (props) => {
  const [selectedId, setSelectedId] = useState(null);
  const [booking, setBooking] = useState(null);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    if (selectedId) {
      getBookingByBookingId(selectedId).then((booking) => {
        console.log("booking: ", booking);
        setBooking(booking);
      });
    }
  }, [selectedId]);

  async function submitDelete() {
    await deleteExam(booking.id);
    setOpenConfirmDeleteModal(false);
    tableRef.current.refresh();
  }

  async function submitCancel() {
    await cancel(booking.id);
    setOpenConfirmCancelModal(false);
    tableRef.current.refresh();
  }

  async function updateBooking(details) {
    await props.handleUpdateBooking(details);
    setOpenRescheduleModal(false);
    tableRef.current.refresh();
  }
  return (
    <React.Fragment>
      {booking ? (
        <RescheduleExamModal
          open={openRescheduleModal}
          booking={booking}
          updateBooking={updateBooking}
          close={() => setOpenRescheduleModal(false)}
        />
      ) : null}

      <ConfirmationModal
        open={openConfirmDeleteModal}
        close={() => setOpenConfirmDeleteModal(false)}
        submit={submitDelete}
        headerText={props.t("ExamTable.deleteExam")}
        confirmationMsg={props.t("ExamTable.confirmDelete")}
      />
      <ConfirmationModal
        open={openConfirmCancelModal}
        close={() => setOpenConfirmCancelModal(false)}
        submit={submitCancel}
        headerText={props.t("RescheduleExam.cancelExam")}
        confirmationMsg={props.t("RescheduleExam.cancelExamMsg")}
      />
      <DataTable
        ref={tableRef}
        columnDefs={[
          {
            headerName: props.t("general.username"),
            field: "username",
            valueGetter: (row) => row.data?.user.username,
            filter: "agTextColumnFilter",
            filterParams: textFilterParams,
            flex: 2,
          },
          {
            headerName: props.t("general.firstName"),
            field: "first_name",
            valueGetter: (row) => row.data?.user.first_name,
            filter: "agTextColumnFilter",
            filterParams: textFilterParams,
            flex: 1,
          },
          {
            headerName: props.t("general.lastName"),
            field: "last_name",
            valueGetter: (row) => row.data?.user.last_name,
            filter: "agTextColumnFilter",
            filterParams: textFilterParams,
            flex: 1,
          },

          {
            headerName: props.t("RescheduleExam.dateAndTimeSlot"),
            field: "booking_date",
            filter: "agDateColumnFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              debounceMs: 1000,
              maxNumConditions: 1,
            },
            valueGetter: (row) =>
              row.data
                ? format(new Date(row.data?.booking_date), "MMMM do, yyyy")
                : null,
            flex: 2,
          },
          {
            headerName: props.t("general.examName"),
            field: "exam",
            valueGetter: (row) => row.data?.exam.title,
            filter: AsyncDropdownFilter,
            filterParams: {
              url: `${process.env.REACT_APP_API_ADDRESS}/api/examinations`,
              text: "Exam",
              keyValue: "id",
              keyText: "title",
            },
            flex: 2,
          },
          {
            headerName: props.t("general.status"),
            field: "approved",
            cellRenderer: StatusRenderer,
            cellRendererParams: (row) => ({
              text: row.data ? props.t(`general.${row.data.approved}`) : "",
            }),
            valueGetter: (row) => ({
              status: row.data?.approved,
              onClick: (e) => {
                e.preventDefault();
                handleDownloadUpload(row.data?.id);
              },
              file_upload_required: row.data?.exam.file_upload_required,
            }),
            filter: SimpleDropdownFilter,
            filterParams: {
              options: [
                { value: "approved", text: props.t("general.approved") },
                { value: "rejected", text: props.t("general.rejected") },
                { value: "pending", text: props.t("general.pending") },
                { value: "completed", text: props.t("general.completed") },
              ],
              text: "Status",
            },
            flex: 2,
          },
          {
            headerName: props.t("general.actions"),
            cellRenderer: DropdownRenderer,
            cellRendererParams: {
              text: props.t("general.edit"),
            },
            valueGetter: (row) => {
              if (row.data) {
                return [
                  {
                    text: props.t("RescheduleExam.reschedule"),
                    onClick: () => {
                      setSelectedId(row.data.id);
                      setOpenRescheduleModal(true);
                    },
                  },
                  {
                    text: props.t("general.delete"),
                    onClick: () => {
                      setSelectedId(row.data.id);
                      setOpenConfirmDeleteModal(true);
                    },
                  },
                  {
                    text: props.t("general.cancel"),
                    onClick: () => {
                      setSelectedId(row.data.id);
                      setOpenConfirmCancelModal(true);
                    },
                  },
                ];
              }
            },
            cellStyle: {
              overflow: "inherit",
              textAlign: "center",
              alignSelf: "flex-end",
            },
            flex: 2,
            filter: false,
            sortable: false,
          },
        ]}
        url={`${process.env.REACT_APP_API_ADDRESS}/api/bookings/table`}
        getFilterParams={getFilterParams}
      />
    </React.Fragment>
  );
};

export default withTranslation()(RescheduleExamTable);
