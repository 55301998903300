const initState = {
  tokenParsed: null,
  realmAccess: null,
  token: null,
  subject: null,
};

const UPDATE_KEYCLOAK = "UPDATE_KEYCLOAK";

// Action creators
export const updateKeycloak = (val) => {
  return { type: UPDATE_KEYCLOAK, payload: val };
};

// Reducer
export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_KEYCLOAK:
      return {
        ...state,
        tokenParsed: action.payload.tokenParsed,
        token: action.payload.token,
        realmAccess: action.payload.realmAccess,
        subject: action.payload.subject,
      };
    default:
      return state; // Default case
  }
};
