import React from "react";
import { Button, Form, Modal, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

const RejectModal = (props) => (
  <Modal
    open={props.open}
    onClose={() => {
      props.updateRejectModal();
    }}
    className="modalHOTFIX"
  >
    <Modal.Header>{props.t("RejectModal.rejectBooking")}</Modal.Header>
    <Modal.Content>
      <Form>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>{props.t("RejectModal.rejectReasoning")}</label>
                <textarea
                  onChange={(e) => props.updateReason(e.target.value)}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                onClick={() => props.updateRejectModal()}
                color="red"
                floated="right"
              >
                {props.t("general.close")}
              </Button>
              <Button
                onClick={(e) => {
                  props.updateApproved({
                    id: props.bookingId,
                    approved: "rejected",
                  });
                  props.updateRejectModal();
                }}
                color="green"
                floated="right"
              >
                {props.t("general.reject")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Modal.Content>
  </Modal>
);

export default withTranslation()(RejectModal);
