import React, { Fragment } from "react";
import { Header, Grid, Segment, Message, Form, Table } from "semantic-ui-react";
import { withTranslation, Trans } from "react-i18next";
import { format } from "date-fns";

const ExamDetails = (props) => (
  <Fragment>
    {props.step > 1 ? (
      <Segment padded>
        <Header>{props.t("ExamDetails.header")}</Header>
        <Grid.Column>
          <Form.Field>
            <Message color="teal">{props.examTitle}</Message>
          </Form.Field>
        </Grid.Column>
      </Segment>
    ) : null}

    <Segment padded>
      <Header sub>{props.t("ExamDetails.examDetails")}</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Header sub>{props.t("general.duration")}</Header>
            </Table.Cell>
            <Table.Cell>
              {props.duration || "-"} {props.t("general.hours")}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header sub>{props.t("ExamDetails.examPrice")}</Header>
            </Table.Cell>
            <Table.Cell>
              <Trans
                i18nKey="general.formattedPrice"
                defaults="{{price}}"
                values={{ price: props.cost }}
              />
            </Table.Cell>
          </Table.Row>

          {props.step > 4 ? (
            <Table.Row>
              <Table.Cell>
                <Header sub>{props.t("ExamDetails.notes")}</Header>
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          ) : null}

          {props.step > 3 ? (
            <Fragment>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{props.t("ExamDetails.tax")}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Trans
                    i18nKey="general.formattedPrice"
                    defaults="{{price}}"
                    values={{ price: props.taxes.toFixed(2) }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{props.t("ExamDetails.discount")}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Trans
                    i18nKey="general.formattedPrice"
                    defaults="{{price}}"
                    values={{ price: props.discount.toFixed(2) }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{props.t("general.total")}</Header>
                </Table.Cell>
                <Table.Cell>
                  <Trans
                    i18nKey="general.formattedPrice"
                    defaults="{{price}}"
                    values={{ price: props.total.toFixed(2) }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{props.t("general.coupon")}</Header>
                </Table.Cell>
                <Table.Cell>{props.coupon || "-"}</Table.Cell>
              </Table.Row>
            </Fragment>
          ) : null}

          {props.step > 2 ? (
            <Table.Row>
              <Table.Cell>
                <Header sub>{props.t("ExamDetails.datePending")}</Header>
              </Table.Cell>
              <Table.Cell>
                {props.examDate ? format(props.examDate, "MMMM do, yyyy") : "-"}
              </Table.Cell>
            </Table.Row>
          ) : null}

          {props.step > 3 ? (
            <Fragment>
              <Table.Row>
                <Table.Cell>
                  <Header sub>{props.t("ExamDetails.creditCardNumber")}</Header>
                </Table.Cell>
                <Table.Cell>{`${props.pan}` || "-"}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header sub>
                    {props.t("ExamDetails.creditCardExpiration")}
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  {props.requires_payment
                    ? `${props.expMonth}-${props.expYear}` || "-"
                    : "N/A"}
                </Table.Cell>
              </Table.Row>
            </Fragment>
          ) : null}
        </Table.Body>
      </Table>
      {props.step === 1 ? (
        <Segment>
          <Header sub>{props.t("ExamDetails.description")}</Header>
          {props.description || "-"}
        </Segment>
      ) : null}
    </Segment>
  </Fragment>
);

export default withTranslation()(ExamDetails);
