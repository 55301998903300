import { useParams, useSearchParams } from "react-router-dom";

export default function withRouter(Component) {
  return (props) => (
    <Component
      {...props}
      params={useParams()}
      searchParams={useSearchParams()}
    />
  );
}
