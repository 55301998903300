import React from "react";
import { Button, Modal, Header, Form, Input } from "semantic-ui-react";
import { updateAllUrl, updateAllPushModal } from "../reducers/session";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class AllPushUrlModal extends React.Component {
  constructor(props) {
    super(props);
    this.updateAllUrl = this.updateAllUrl.bind(this);
  }

  updateAllUrl(e) {
    this.props.updateAllUrl(e.target.value);
  }

  render() {
    return (
        <Modal
          open={this.props.showAllPushModal}
          closeIcon
          onClose={() => {
            this.props.updateAllPushModal();
          }}
        >
        <Modal.Header>
          {this.props.t("ProctoringSession.pushExamUrl")}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>
              {this.props.t("ProctoringSession.pushExamAllUsers")}
            </Header>
            <Form>
              <Form.Field>
                <label>{this.props.t("ProctoringSession.url")}</label>
                <Input onChange={this.updateAllUrl} />
              </Form.Field>
              <Button type="button" onClick={this.props.allPushUrl}>
                {this.props.t("general.enter")}
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      url: state.session.url,
      showAllPushModal: state.session.showAllPushModal,
    }),
    { updateAllUrl, updateAllPushModal }
  )(AllPushUrlModal)
);
