import {
  updateExistingBooking,
  getScheduledBookings,
  getPastBookings,
  getFutureBookings,
  getBookingByBookingId,
  createBooking,
  getAllBookings,
  updateBookingApproved,
  deleteExam,
  getApprovedBookings,
  createBlockedDay,
  fetchBlockedDays,
  fetchBlockedDaysInMonth,
  deleteBlock,
  updateExisting,
  getBookings,
  reschedule,
  cancel,
} from "../lib/bookingServices";
import { sendRequestForRecordings } from "../lib/recordingServices";
import moment from "moment";

const initState = {
  pending: null,
  completed: null,
  approved: null,
  rejected: null,
  cancelled: null,
  banned: null,

  scheduledBookings: [],
  pastBookings: [],
  booking: null,
  showDeleteModal: false,
  showRejectModal: false,
  showRescheduleModal: false,
  taxRate: 0,
  taxType: "",
  futureBookings: [],
  approvedBookings: [],
  allBookings: [],
  examToDelete: {},
  bookingToEdit: {},
  exams: [],
  futureExams: [],
  futureEvents: [],
  events: [],
  error: null,
  reasoning: "",
  showOnlyFutureBookings: true,
  blockedDays: [],
  blockedDaysInMonth: [],
  blocked_from_time: "",
  blocked_to_time: "",
  isUpdating: false,
};

const LOAD_BOOKINGS = "LOAD_BOOKINGS";
const SHOW_DELETE_MODAL = "SHOW_DELETE_MODAL";
const SHOW_REJECT_MODAL = "SHOW_REJECT_MODAL";
const LOAD_SCHEDULED_BOOKINGS = "LOAD_SCHEDULED_BOOKINGS";
const LOAD_PAST_BOOKINGS = "LOAD_PAST_BOOKINGS";
const LOAD_FUTURE_BOOKINGS = "LOAD_FUTURE_BOOKINGS";
const LOAD_ALL_BOOKINGS = "LOAD_ALL_BOOKINGS";
const UPDATE_TAX_RATE = "UPDATE_TAX_RATE";
const UPDATE_TAX_TYPE = "UPDATE_TAX_TYPE";
const SET_APPROVED = "SET_APPROVED";
const LOAD_BOOKING = "LOAD_BOOKING";
const UPDATE_EXAM_TO_DELETE = "UPDATE_EXAM_TO_DELETE";
const UPDATE_BOOKING_TO_EDIT = "UPDATE_BOOKING_TO_EDIT";
const UPDATE_BOOKING = "UPDATE_BOOKING";
const UPDATE_REASON = "UPDATE_REASON";
const LOAD_APPROVED_BOOKINGS = "LOAD_APPROVED_BOOKINGS";
const UPDATE_SHOW_ONLY_FUTURE_BOOKINGS = "UPDATE_SHOW_ONLY_FUTURE_BOOKINGS";
const LOAD_BLOCKED_DAYS = "LOAD_BLOCKED_DAYS";
const LOAD_BLOCKED_DAYS_FOR_SIX_MONTHS = "LOAD_BLOCKED_DAYS_FOR_SIX_MONTHS";
const UPDATE_BLOCKED_FROM_TIME = "UPDATE_BLOCKED_FROM_TIME";
const UPDATE_BLOCKED_TO_TIME = "UPDATE_BLOCKED_TO_TIME";
const SET_IS_UPDATING = "SET_IS_UPDATING";
const UPDATE_RESCHEDULED_USER = "UPDATE_RESCHEDULED_USER";

export const loadBookings = (payload) => ({ type: LOAD_BOOKINGS, payload });

export const updateReason = (val) => ({ type: UPDATE_REASON, payload: val });
export const loadScheduledBookings = (bookings) => ({
  type: LOAD_SCHEDULED_BOOKINGS,
  payload: bookings,
});
export const loadPastBookings = (bookings) => ({
  type: LOAD_PAST_BOOKINGS,
  payload: bookings,
});
export const loadFutureBookings = (bookings) => ({
  type: LOAD_FUTURE_BOOKINGS,
  payload: bookings,
});
export const loadApprovedBookings = (bookings) => ({
  type: LOAD_APPROVED_BOOKINGS,
  payload: bookings,
});
export const updateTaxRate = (rate) => ({
  type: UPDATE_TAX_RATE,
  payload: rate,
});
export const updateTaxType = (type) => ({
  type: UPDATE_TAX_TYPE,
  payload: type,
});
export const loadAllBookings = (bookings) => ({
  type: LOAD_ALL_BOOKINGS,
  payload: bookings,
});
export const setApproved = (res) => ({ type: SET_APPROVED, payload: res });
export const loadBooking = (booking) => ({
  type: LOAD_BOOKING,
  payload: booking,
});
export const updateExamToDelete = (val) => ({
  type: UPDATE_EXAM_TO_DELETE,
  payload: val,
});
export const updateBookingToEdit = (val) => ({
  type: UPDATE_BOOKING_TO_EDIT,
  payload: val,
});
export const updateBooking = (val) => ({ type: UPDATE_BOOKING, payload: val });
export const updateDeleteModal = (val) => ({ type: SHOW_DELETE_MODAL });
export const updateRejectModal = (val) => ({ type: SHOW_REJECT_MODAL });
export const updateShowOnlyFutureBookings = (val) => ({
  type: UPDATE_SHOW_ONLY_FUTURE_BOOKINGS,
  payload: val,
});
export const loadBlockedDays = (val) => ({
  type: LOAD_BLOCKED_DAYS,
  payload: val,
});
export const loadBlockedDaysForSixMonths = (val) => ({
  type: LOAD_BLOCKED_DAYS_FOR_SIX_MONTHS,
  payload: val,
});
export const updateBlockedFromTime = (time) => ({
  type: UPDATE_BLOCKED_FROM_TIME,
  payload: time,
});
export const updateBlockedToTime = (time) => ({
  type: UPDATE_BLOCKED_TO_TIME,
  payload: time,
});
export const setIsUpdating = (val) => ({ type: SET_IS_UPDATING, payload: val });

export const fetchScheduledBookings = (user_id) => {
  return (dispatch) => {
    getScheduledBookings(user_id).then((bookings) =>
      dispatch(loadScheduledBookings(bookings))
    );
  };
};

export const fetchPastBookings = (user_id) => {
  return (dispatch) => {
    getPastBookings(user_id).then((bookings) =>
      dispatch(loadPastBookings(bookings))
    );
  };
};

export const fetchFutureBookings = () => {
  return (dispatch) => {
    getFutureBookings().then((bookings) => {
      return dispatch(loadFutureBookings(bookings));
    });
  };
};

export const fetchBookings = (status, only_future) => {
  return (dispatch) => {
    getBookings(status, only_future).then((bookings) => {
      return dispatch(loadBookings({ bookings, status }));
    });
  };
};

export const fetchApprovedBookings = () => {
  return (dispatch) => {
    getApprovedBookings().then((bookings) => {
      return dispatch(loadApprovedBookings(bookings));
    });
  };
};

export const fetchBooking = (booking_id) => {
  return (dispatch) => {
    getBookingByBookingId(booking_id).then((booking) => {
      return dispatch(loadBooking(booking));
    });
  };
};

export const updateApproved = (booking) => (dispatch) => {
  return updateBookingApproved(booking).then((booking) => {
    dispatch(setApproved(booking));
    return booking;
  });
};

export const requestRecordings = (booking_id) => async (dispatch) => {
  const response = await sendRequestForRecordings(booking_id);
  return response;
};

export const rescheduleBooking =
  (booking, status, only_future) => (dispatch) => {
    return reschedule(booking)
      .then((responseData) => {
        dispatch(fetchBookings(status, only_future));
        return responseData;
      })
      .catch((error) => {
        return error;
      });
  };

export const fetchAllBookings = () => {
  return (dispatch) => {
    getAllBookings().then((bookings) => dispatch(loadAllBookings(bookings)));
  };
};

export const storeBlockedDay = (blockedDay) => (dispatch) => {
  return createBlockedDay(blockedDay).then((blockedDay) => {
    dispatch(getBlockedDays());
    return blockedDay;
  });
};

export const getBlockedDays = () => {
  return (dispatch) => {
    fetchBlockedDays().then((days) => dispatch(loadBlockedDays(days)));
  };
};

export const getBlockedDaysForSixMonths = (exam) => {
  return (dispatch) => {
    fetchBlockedDaysInMonth(exam).then((days) =>
      dispatch(loadBlockedDaysForSixMonths(days))
    );
  };
};

export const deleteBlockedDay = (id) => {
  return (dispatch) => {
    deleteBlock(id).then(() => dispatch(getBlockedDays()));
  };
};

export const saveBooking = (booking) => (dispatch) => {
  // Function is expected to return a promise
  return createBooking(booking).then((booking) => {
    dispatch(fetchScheduledBookings());
    return booking;
  });
};

export const deleteExamCreator = (booking, status, only_future) => {
  return (dispatch) => {
    deleteExam(booking.id).then((booking) =>
      dispatch(fetchBookings(status, only_future))
    );
  };
};

export const cancelBooking = (booking, status, only_future) => {
  return (dispatch) => {
    cancel(booking.id).then(() => dispatch(fetchBookings(status, only_future)));
  };
};

export const updateExistingBlockedDay = (blockedDay) => (dispatch) => {
  return updateExisting(blockedDay).then((blockedDay) => {
    dispatch(getBlockedDays());
    return blockedDay;
  });
};

export default (state = initState, action) => {
  switch (action.type) {
    case LOAD_BOOKINGS:
      return { ...state, [action.payload.status]: action.payload.bookings };
    case UPDATE_REASON:
      return { ...state, reasoning: action.payload };
    case LOAD_BOOKING:
      return { ...state, booking: action.payload };
    case LOAD_SCHEDULED_BOOKINGS:
      return { ...state, scheduledBookings: action.payload };
    case LOAD_PAST_BOOKINGS:
      return { ...state, pastBookings: action.payload };
    case LOAD_FUTURE_BOOKINGS:
      return { ...state, futureBookings: action.payload };
    case LOAD_APPROVED_BOOKINGS:
      const approvedBookings = action.payload.filter((booking) => {
        if (state.showOnlyFutureBookings) {
          return moment(booking.booking_date).isAfter(moment());
        }
        return true;
      });
      return { ...state, approvedBookings: approvedBookings };
    case UPDATE_EXAM_TO_DELETE:
      return { ...state, examToDelete: action.payload };
    case UPDATE_BOOKING_TO_EDIT:
      return { ...state, bookingToEdit: action.payload };
    case UPDATE_BOOKING:
      return { ...state, booking: action.payload };
    case UPDATE_BLOCKED_FROM_TIME:
      return { ...state, blocked_from_time: action.payload };
    case UPDATE_BLOCKED_TO_TIME:
      return { ...state, blocked_to_time: action.payload };
    case SET_IS_UPDATING:
      return { ...state, isUpdating: action.payload };
    case SHOW_DELETE_MODAL:
      return { ...state, showDeleteModal: !state.showDeleteModal };
    case SHOW_REJECT_MODAL:
      return { ...state, showRejectModal: !state.showRejectModal };
    case LOAD_ALL_BOOKINGS:
      let exams = action.payload.map((data) => {
        return data.exam.title;
      });
      //remove duplicates
      let unique_array = exams.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      exams = unique_array.map((data) => {
        return { text: data, value: data };
      });

      let events = [];
      action.payload.forEach((data, i) => {
        if (data.approved === "approved") {
          let start = moment(data.booking_date);
          let end = moment(data.booking_date).add(data.exam.duration, "hours");
          let booking = {
            id: data.id,
            name: `${data.user.first_name} ${data.user.last_name}`,
            username: data.user.username,
            user_id: data.user.user_id,
            waiting: false,
            checkedIn: false,
            busy: false,
            banned: false,
            completed: false,
            disconnected: false,
          };

          let index = -1;
          events.forEach((event, i) => {
            if (
              event.exam === data.exam.title &&
              event.booking_date === data.booking_date
            ) {
              index = i;
            }
          });

          if (index === -1) {
            events.push({
              booking_date: data.booking_date,
              start: start._d,
              end: end._d,
              exam: data.exam.title,
              exam_id: data.exam.id,
              bookings: [booking],
              title: data.exam.title,
            });
          } else {
            if (data.exam.title === events[index].exam) {
              events[index].bookings.push(booking);
            } else {
              events.push({
                booking_date: data.booking_date,
                start: start._d,
                end: end._d,
                exam: data.exam.title,
                exam_id: data.exam.id,
                bookings: [booking],
                title: data.exam.title,
              });
            }
          }
        }
      });
      return {
        ...state,
        allBookings: action.payload,
        exams: exams,
        events: events,
      };
    case UPDATE_TAX_RATE:
      return { ...state, taxRate: action.payload };
    case UPDATE_TAX_TYPE:
      return { ...state, taxType: action.payload };
    case UPDATE_SHOW_ONLY_FUTURE_BOOKINGS:
      return { ...state, showOnlyFutureBookings: action.payload };
    case SET_APPROVED:
      let allBookings = state.allBookings.map((data) => {
        if (data.id === action.payload.id) {
          return {
            ...data,
            approved: action.payload.approved,
          };
        }
        return data;
      });
      return { ...state, allBookings: allBookings };
    case LOAD_BLOCKED_DAYS:
      return { ...state, blockedDays: action.payload };
    case LOAD_BLOCKED_DAYS_FOR_SIX_MONTHS:
      // const blockedDaysInMonth = [];
      // for(let block of action.payload ) {
      //     const day = `${block.day}`.length === 1 ? `0${block.day}` : block.day;
      //     const month = `${block.month}`.length === 1 ? `0${block.month}` : block.month;

      //     if(block.every_year){
      //         blockedDaysInMonth.push(`${moment().format('YYYY')}-${month}-${day}`);
      //         blockedDaysInMonth.push(`${moment().add(1, 'years').format('YYYY')}-${month}-${day}`);
      //     }else{
      //         blockedDaysInMonth.push(`${block.year}-${month}-${day}`);
      //     }
      // }
      return { ...state, blockedDaysInMonth: action.payload };
    default:
      return state;
  }
};
