import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { addNotification } from "./../reducers/notification";

function RequireProfileImage({ children, kc, t, addNotification }) {
  const url = `${process.env.REACT_APP_API_ADDRESS}/api/images/profile/${
    kc.tokenParsed.sub
  }_user_profile_img.png?token=${kc.token}&cb=${Math.round(
    new Date().getTime() / 1000
  )})`;

  //TODO: Need to redo this since sync xhttp requests no longer supported
  // const http = new XMLHttpRequest();
  // http.open("HEAD", url, false);
  // http.send();
  // if (http.status === 404) {
  //   addNotification({
  //     message: t("AccountDetails.addPersonalHeadshot"),
  //     type: "error",
  //   });
  //   return <Navigate to="/account-details" />;
  // }

  return children;
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
    }),
    { addNotification }
  )(RequireProfileImage)
);
