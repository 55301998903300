import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment-timezone";
import i18n from "./../i18n";

import RescheduleExamTable from "../components/RescheduleExamTable";
import {
  fetchApprovedBookings,
  updateExamToDelete,
  deleteExamCreator,
  updateBookingToEdit,
  updateDeleteModal,
  updateShowOnlyFutureBookings,
  rescheduleBooking,
  fetchBookings,
  cancelBooking,
  fetchBooking,
} from "./../reducers/booking";
import MainMenu from "./../components/MainMenu";
import BookingReschedule from "./../components/emails/BookingReschedule";
import { addNotification } from "./../reducers/notification";
import parseTimezone from "../lib/helperServices";
import { sendEmail } from "./../lib/emailService";

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayError: false,
      openDelete: false,
      error: "",
      showRescheduleModal: false,
      statusFilter: "approved",
      showConfirmationModal: false,
      showOnlyFuture: true,
      modalText: {
        header: "",
        msg: "",
      },
    };
    this.handleUpdateBooking = this.handleUpdateBooking.bind(this);
  }
  handleUpdateBooking(data) {
    return new Promise((resolve, reject) => {
      const { examDate, booking, sendRescheduleEmail } = data;
      this.props
        .rescheduleBooking(
          {
            examDate,
            id: booking.id,
            user_id: booking.user.user_id,
          },
          this.state.statusFilter,
          this.state.showOnlyFuture
        )
        .then((response) => {
          const updatedBooking = response.booking;
          const userData = response.userData;

          let timezone = parseTimezone(userData.attributes.timezone[0]);

          this.props.addNotification({
            title: this.props.t("general.success"),
            message:
              "Exam rescheduled successfully for: " +
              moment(updatedBooking.examDate).format("MMMM Do YYYY - h:mm A"),
            type: "success",
            timeout: true,
          });

          if (sendRescheduleEmail) {
            let userLanguage = "";

            if (userData.attributes && userData.attributes.locale) {
              userLanguage = i18n.getFixedT(userData.attributes.locale[0]);
            } else {
              userLanguage = i18n.getFixedT("en");
            }

            sendEmail({
              to: userData.email,
              subject: userLanguage("RescheduleExam.resheduleEmailSubject"),
              component: (
                <BookingReschedule
                  t={userLanguage}
                  name={`${userData.firstName} ${userData.lastName}`}
                  examTitle={booking.exam.title}
                  timezone={userData.attributes.timezone[0]}
                  bookingDate={moment(updatedBooking.examDate)
                    .tz(timezone)
                    .format("MMMM Do YYYY - h:mm A")}
                  duration={booking.exam.duration}
                  examRules={
                    booking.exam.student_rules
                      ? booking.exam.student_rules
                      : "N/A"
                  }
                />
              ),
            }).then(() => resolve());
          } else {
            resolve();
          }
        })
        .catch((e) => {
          this.props.addNotification({
            title: this.props.t("general.error"),
            message: "Rescheduling Error.",
            type: "error",
            timeout: true,
          });

          reject(e);
        });
    });
  }
  render() {
    return (
      <Container>
        <MainMenu />
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <RescheduleExamTable
                  handleUpdateBooking={this.handleUpdateBooking}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      bookings: state.bookings,
    }),
    {
      addNotification,
      fetchBookings,
      fetchBooking,
      fetchApprovedBookings,
      updateExamToDelete,
      deleteExamCreator,
      updateBookingToEdit,
      updateDeleteModal,
      updateShowOnlyFutureBookings,
      rescheduleBooking,
      cancelBooking,
    }
  )(Schedule)
);
