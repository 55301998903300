import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Header, Grid, Divider } from "semantic-ui-react";
import { fetchPastBookings } from "../../reducers/booking";
import ExamBlock from "./ExamBlock";
import { withTranslation } from "react-i18next";
import { format } from "date-fns";
import { TZDate } from "@date-fns/tz";
import parseTimezone from "../../lib/helperServices";

function PastExams({ bookings, kc, t, fetchPastBookings }) {
  useEffect(() => {
    fetchPastBookings(kc.tokenParsed.sub);
  }, [kc.tokenParsed.sub]);

  let timezone = parseTimezone(
    kc.tokenParsed.timezone,
    kc.tokenParsed.province
  );
  let timezone_array = kc.tokenParsed.timezone.split("");
  const dateFormat =
    localStorage.getItem("lang") === "en"
      ? "MMMM do yyyy - h:mm a"
      : "MMMM do yyyy - HH:mm";
  return (
    <div>
      <Container style={{ marginTop: "2em" }}>
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <Divider className="home-divider" />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={4}>
                <Header textAlign="center" as="h1">
                  {t("PastExams.header")}
                </Header>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <Divider className="home-divider" />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid>
            <Grid.Row>
              {bookings.map((data, i) => {
                if (data.user_id === kc.tokenParsed.sub) {
                  return (
                    <Grid.Column
                      key={`${i}_past_exam`}
                      mobile={16}
                      tablet={10}
                      computer={5}
                    >
                      <ExamBlock
                        timezone={`${timezone_array[0]}${timezone_array[2]}`}
                        past={true}
                        date={format(
                          new TZDate(data.booking_date, timezone),
                          dateFormat
                        )}
                        status={data.approved}
                        title={
                          data.exam.title !== undefined ? data.exam.title : ""
                        }
                      />
                    </Grid.Column>
                  );
                } else {
                  return null;
                }
              })}
            </Grid.Row>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default withTranslation()(
  connect(
    (state) => ({
      bookings: state.bookings.pastBookings,
      kc: state.keycloak,
    }),
    { fetchPastBookings }
  )(PastExams)
);
