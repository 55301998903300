import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { addDays, format } from "date-fns";
import axios from "axios";

import { updateApproved, updateReason } from "./../reducers/booking";
import RejectModal from "./RejectModal";

import DataTable, {
  SimpleDropdownFilter,
  AsyncDropdownFilter,
} from "./Table/DataTable";
import { DropdownRenderer } from "./Table/CustomComponents";

function StatusRenderer(params) {
  const status = params.value.status;
  const color = ["approved", "completed"].includes(status) ? "green" : "red";
  return (
    <React.Fragment>
      <span style={{ color: status === "pending" ? null : color }}>
        {params.text}
      </span>
      {params.value.file_upload_required ? (
        <a href="#" style={{ color: "black" }} onClick={params.value.onClick}>
          <Icon name="file outline" size="large" />
        </a>
      ) : null}
    </React.Fragment>
  );
}

const textFilterParams = {
  filterOptions: ["contains"],
  trimInput: true,
  debounceMs: 1000,
  maxNumConditions: 1,
};

function getFilterParams(filterValues) {
  const params = {
    username: filterValues.username?.filter,
    firstName: filterValues.first_name?.filter,
    lastName: filterValues.last_name?.filter,
    status: filterValues.approved,
    from: filterValues.booking_date?.dateFrom,
    to: filterValues.booking_date?.dateTo,
    exam: filterValues.exam,
  };

  if (filterValues.booking_date) {
    // Server expects date in 'to' with 'from' null for filtering for dates before
    if (filterValues.booking_date.type === "lessThan") {
      params.to = params.from;
      params.from = null;
    } else if (filterValues.booking_date.type === "equals") {
      // If searching for specific date, just do a ranged filter which is exclusive of 'to' date
      params.to = addDays(new Date(params.from), 1);
    }
  }

  return params;
}

function handleDownloadUpload(id) {
  axios
    .get(`${process.env.REACT_APP_API_ADDRESS}/api/bookings/${id}/upload`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = response.headers["content-disposition"]
        .split("filename=")[1]
        .trim();
      link.setAttribute("download", fileName.substring(1, fileName.length - 1));
      document.body.appendChild(link);
      link.click();
    });
}

const PendingRequestsTable = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const tableRef = useRef();
  function openRejectModal(id) {
    setSelectedId(id);
    setOpen(true);
  }
  function updateReason(value) {
    props.updateReason(value);
  }
  async function update(details) {
    await props.updateApproved(details);
    tableRef.current.refresh();
  }
  return (
    <React.Fragment>
      <RejectModal
        open={open}
        updateRejectModal={() => setOpen(!open)}
        updateReason={updateReason}
        updateApproved={update}
        bookingId={selectedId}
      />
      <DataTable
        ref={tableRef}
        columnDefs={[
          {
            headerName: props.t("general.username"),
            field: "username",
            valueGetter: (row) => row.data?.user.username,
            filter: "agTextColumnFilter",
            filterParams: textFilterParams,
            flex: 2,
          },
          {
            headerName: props.t("general.firstName"),
            field: "first_name",
            valueGetter: (row) => row.data?.user.first_name,
            filter: "agTextColumnFilter",
            filterParams: textFilterParams,
            flex: 1,
          },
          {
            headerName: props.t("general.lastName"),
            field: "last_name",
            valueGetter: (row) => row.data?.user.last_name,
            filter: "agTextColumnFilter",
            filterParams: textFilterParams,
            flex: 1,
          },
          {
            headerName: props.t("general.birthDate"),
            field: "dob",
            valueGetter: (row) => {
              return row.data?.user.dob
                ? format(new Date(row.data?.user.dob), "MMMM do, yyyy")
                : "";
            },
            filter: false,
            flex: 1,
          },
          {
            headerName: props.t("PendingRequests.bookingDate"),
            field: "booking_date",
            filter: "agDateColumnFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              debounceMs: 1000,
              maxNumConditions: 1,
            },
            valueGetter: (row) =>
              row.data
                ? format(new Date(row.data?.booking_date), "MMMM do, yyyy")
                : null,
            flex: 2,
          },
          {
            headerName: props.t("general.status"),
            field: "approved",
            cellRenderer: StatusRenderer,
            cellRendererParams: (row) => ({
              text: row.data ? props.t(`general.${row.data.approved}`) : "",
            }),
            valueGetter: (row) => ({
              status: row.data?.approved,
              onClick: (e) => {
                e.preventDefault();
                handleDownloadUpload(row.data?.id);
              },
              file_upload_required: row.data?.exam.file_upload_required,
            }),
            filter: SimpleDropdownFilter,
            filterParams: {
              options: [
                { value: "approved", text: props.t("general.approved") },
                { value: "rejected", text: props.t("general.rejected") },
                { value: "pending", text: props.t("general.pending") },
                { value: "completed", text: props.t("general.completed") },
              ],
              text: "Status",
            },
            flex: 2,
          },
          {
            headerName: props.t("general.exam"),
            field: "exam",
            valueGetter: (row) => row.data?.exam.title,
            filter: AsyncDropdownFilter,
            filterParams: {
              url: `${process.env.REACT_APP_API_ADDRESS}/api/examinations`,
              text: "Exam",
              keyValue: "id",
              keyText: "title",
            },
            flex: 2,
          },
          {
            headerName: props.t("PendingRequests.submittedDate"),
            field: "createdAt",
            valueGetter: (row) =>
              row.data
                ? format(new Date(row.data?.createdAt), "MMMM do, yyyy")
                : null,
            filter: false,
            flex: 1,
          },
          {
            headerName: props.t("general.learningInstitution"),
            field: "organization_name",
            valueGetter: (row) => row.data?.client?.organization_name,
            filter: "agTextColumnFilter",
            filterParams: textFilterParams,
            flex: 1,
          },
          {
            headerName: props.t("general.actions"),
            cellRenderer: DropdownRenderer,
            cellRendererParams: {
              text: props.t("general.edit"),
            },
            valueGetter: (row) => {
              if (row.data) {
                return [
                  {
                    text: props.t("PendingRequests.downloadFile"),
                    onClick: () => handleDownloadUpload(row.data.id),
                  },
                  {
                    text: props.t("general.approve"),
                    onClick: () =>
                      update({
                        id: row.data.id,
                        approved: "approved",
                      }),
                  },
                  {
                    text: props.t("general.reject"),
                    onClick: () => openRejectModal(row.data.id),
                  },
                ];
              }
            },
            cellStyle: {
              overflow: "inherit",
              textAlign: "center",
              alignSelf: "flex-end",
            },
            flex: 2,
            filter: false,
            sortable: false,
          },
        ]}
        url={`${process.env.REACT_APP_API_ADDRESS}/api/bookings/table`}
        getFilterParams={getFilterParams}
      />
    </React.Fragment>
  );
};

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
    }),
    { updateApproved, updateReason }
  )(PendingRequestsTable)
);
