import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import { format } from "date-fns";
import { TZDate } from "@date-fns/tz";
import axios from "axios";
import { withTranslation } from "react-i18next";

import { updateApproved } from "./../reducers/booking";
import { fetchLearners } from "./../reducers/learners";
import MainMenu from "./../components/MainMenu";
import { sendEmail } from "./../lib/emailService";
import PurchaseReceipt from "./../components/emails/PurchaseReceipt";
import BookingApproval from "./../components/emails/BookingApproval";
import BookingRejected from "./../components/emails/BookingRejected";
import i18n from "./../i18n";
import PendingRequestsTable from "../components/PendingRequestsTable";

class PendingRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayError: false,
      openReject: false,
      error: "",
      reason: "",
    };

    this.handleDownloadUpload = this.handleDownloadUpload.bind(this);
    this.updateApproved = this.updateApproved.bind(this);

    this.dateFormat =
      localStorage.getItem("lang") === "en"
        ? "MMMM do yyyy - h:mm a"
        : "MMMM do yyyy - HH:mm";
  }
  handleDownloadUpload(id) {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/api/bookings/${id}/upload`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .trim();
        link.setAttribute(
          "download",
          fileName.substring(1, fileName.length - 1)
        );
        document.body.appendChild(link);
        link.click();
      });
  }
  renderStatusColumn(row) {
    const status = row.data.approved;
    const color = ["approved", "completed"].includes(status) ? "green" : "red";
    return (
      <span style={{ color: status === "pending" ? null : color }}>
        {`${this.props.t(`general.${status}`)} `}
      </span>
    );
  }

  updateApproved = (details) => {
    return new Promise((resolve, reject) => {
      const timeFormat =
        localStorage.getItem("lang") === "en"
          ? "MMMM Do YYYY - h:mm A"
          : "MMMM Do YYYY - HH:mm";
      this.props
        .updateApproved(details)
        .then((booking) => {
          const t = i18n.getFixedT(
            booking.attributes.locale ? booking.attributes.locale[0] : "en"
          );

          if (details.approved === "approved") {
            sendEmail({
              to: booking.email,
              subject: t("PendingRequests.receiptEmailSubject"),
              component: (
                <PurchaseReceipt
                  t={t}
                  timeFormat={timeFormat}
                  name={`${booking.firstName} ${booking.lastName}`}
                  invoiceNumber={
                    booking.status.order ? booking.status.order.order_id : "N/A"
                  }
                  examTitle={booking.status.exam.title}
                  examCost={
                    booking.status.request
                      ? Number(booking.status.request.amount).toFixed(2)
                      : "0"
                  }
                  taxRate={
                    booking.status.request
                      ? booking.status.request.tax_rate
                      : "N/A"
                  }
                  taxCost={
                    booking.status.request
                      ? Number(booking.status.request.taxes).toFixed(2)
                      : "N/A"
                  }
                  total={
                    booking.status.request
                      ? Number(
                          booking.status.request.amount +
                            booking.status.request.taxes
                        ).toFixed(2)
                      : "$0"
                  }
                />
              ),
            });

            let timezone = "";
            let acr = "";
            switch (booking.attributes.timezone[0]) {
              case "NST":
                timezone = "Canada/Newfoundland";
                acr = "NT";
                break;
              case "AST":
                timezone = "Canada/Atlantic";
                acr = "AT";
                break;
              case "EST":
                timezone = "Canada/Eastern";
                acr = "ET";
                break;
              case "CST":
                if (booking.attributes.province[0] === "SK") {
                  timezone = "Canada/Saskatchewan";
                } else {
                  timezone = "Canada/Central";
                }
                acr = "CT";
                break;
              case "MST":
                timezone = "Canada/Mountain";
                acr = "MT";
                break;
              case "PST":
                timezone = "Canada/Pacific";
                acr = "PT";
                break;
            }

            sendEmail({
              to: booking.email,
              subject: t("PendingRequests.bookingConfirmedEmail"),
              component: (
                <BookingApproval
                  name={`${booking.firstName} ${booking.lastName}`}
                  t={t}
                  examTitle={booking.status.exam.title}
                  timezone={acr}
                  bookingDate={format(
                    new TZDate(booking.status.booking_date, timezone),
                    this.dateFormat
                  )}
                  duration={booking.status.exam.duration}
                  examRules={
                    booking.status.exam.student_rules
                      ? booking.status.exam.student_rules
                      : "N/A"
                  }
                />
              ),
            }).then(() => resolve());
          } else {
            sendEmail({
              to: booking.email,
              subject: t("PendingRequests.bookingRejectedEmail"),
              component: (
                <BookingRejected
                  t={t}
                  name={`${booking.firstName} ${booking.lastName}`}
                  invoiceNumber={
                    booking.status.order ? booking.status.order.order_id : "N/A"
                  }
                  examTitle={booking.status.exam.title}
                  reason={this.props.bookings.reasoning}
                />
              ),
            }).then(() => resolve());
          }
        })
        .catch((error) => {
          this.setState({
            displayError: true,
            error: "An error occurred while approving request.",
          });
          reject(error);
        });
    });
  };

  render() {
    return (
      <Container>
        <MainMenu />
        <div>
          <PendingRequestsTable updateApproved={this.updateApproved} />
        </div>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      bookings: state.bookings,
    }),
    {
      fetchLearners,
      updateApproved,
    }
  )(PendingRequests)
);
