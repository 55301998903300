import React from "react";
import { Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { addDays, format } from "date-fns";
import axios from "axios";

import DataTable, { SimpleDropdownFilter } from "./Table/DataTable";
import { LinkRenderer } from "../components/Table/CustomComponents";

const textFilterParams = {
  filterOptions: ["contains"],
  trimInput: true,
  debounceMs: 1000,
};

function getFilterParams(filterValues) {
  const params = {
    username: filterValues.username?.filter,
    firstName: filterValues.first_name?.filter,
    lastName: filterValues.last_name?.filter,
  };

  return params;
}

const LearnersTable = (props) => {
  return (
    <DataTable
      columnDefs={[
        {
          headerName: props.t("general.username"),
          field: "username",
          valueGetter: (row) => row.data?.username,
          filter: "agTextColumnFilter",
          filterParams: textFilterParams,
          flex: 2,
        },
        {
          headerName: props.t("general.firstName"),
          field: "first_name",
          valueGetter: (row) => row.data?.first_name,
          filter: "agTextColumnFilter",
          filterParams: textFilterParams,
          flex: 1,
        },
        {
          headerName: props.t("general.lastName"),
          field: "last_name",
          valueGetter: (row) => row.data?.last_name,
          filter: "agTextColumnFilter",
          filterParams: textFilterParams,
          flex: 1,
        },
        {
          headerName: props.t("AccountDetails.province"),
          field: "province",
          filter: false,
          flex: 1,
        },
        {
          headerName: props.t("AccountDetails.timezone"),
          field: "timezone",
          filter: false,
          flex: 1,
        },
        {
          headerName: props.t("Learners.registeredOn"),
          field: "registered",
          valueGetter: (row) => {
            return row.data?.createdAt
              ? format(new Date(row.data?.createdAt), "MMMM do, yyyy")
              : "";
          },
          filter: false,
          flex: 1,
        },
        {
          headerName: props.t("general.actions"),
          cellRenderer: LinkRenderer,
          cellRendererParams: {
            text: props.t("Learners.viewBookings"),
          },
          valueGetter: (row) => `/learner-details/${row.data?.user_id}`,
          cellStyle: {
            overflow: "inherit",
            textAlign: "center",
            alignSelf: "flex-end",
          },
          flex: 2,
          filter: false,
          sortable: false,
        },
      ]}
      url={`${process.env.REACT_APP_API_ADDRESS}/api/users/table`}
      getFilterParams={getFilterParams}
    />
  );
};

export default withTranslation()(LearnersTable);
