import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Grid,
  Button,
  Segment,
  Image,
  Message,
} from "semantic-ui-react";
import { fetchExam } from "../reducers/exam";
import MainMenu from "./../components/MainMenu";
import { NavLink } from "react-router-dom";
import socketUtil from "./../util/socket.js";
import * as Sentry from "@sentry/react";
import { withTranslation } from "react-i18next";
import Chat from "../components/Chat";
import moment from "moment";
import {
  addComment,
  updateNewMessage,
  addChatBox,
  clearChatBoxes,
  updateChatBoxes,
  updateTyping,
} from "../reducers/chat";

import { updateMessage, updateShowMessage } from "../reducers/session";
import withRouter from "./../util/withRouter";

const mediasoup = require("mediasoup-client");
const poster = require("../images/webcam_loading.jpg");

const MEDIA_TYPE_VIDEO = "video";
const MEDIA_TYPE_AUDIO = "audio";

class Checkin extends Component {
  constructor(props) {
    super(props);
    this.learner_id = this.props.params.learner_id;
    this.room_id = parseInt(this.props.params.room_id);
    this.handleAccept = this.handleAccept.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
    this.joinCheckIn = this.joinCheckIn.bind(this);
    this.handleLearnerRejoined = this.handleLearnerRejoined.bind(this);
    this.addUserToSession = this.addUserToSession.bind(this);
    this.handleUserTyping = this.handleUserTyping.bind(this);

    this.localVideo = React.createRef();
    this.localAudio = React.createRef();
    this.localStream = null;

    this.state = {
      learnerRejoined: false,
      learnerLeft: false,
      producerTransports: [],
      consumerTransports: [],
      alreadyConnected: false,
      learnersUsername: "",
      learnersFirstName: "",
      learnersLastName: "",
      webcamNotReadable: false,
      usertyping: false,
    };
  }

  componentWillUnmount() {
    this.props.socket.emit("leaveCheckIn", {
      room: this.room_id,
      role: "proctor",
      learner_id: this.learner_id,
      user_id: this.props.kc.tokenParsed.sub,
    });
    this.props.socket.off("startCommunication");
    this.props.socket.off("learnerLeftCheckIn");
    this.props.socket.off("webcamViewerConnected");
    this.props.socket.off("learnerRejoined");
    this.props.socket.off("startCommunication");
    this.props.socket.off("learnerLeftCheckIn");
    this.props.socket.off("webcamViewerConnected");
    this.props.socket.off("learnerRejoined");
    this.props.socket.off("message");
    this.props.socket.off("usertyping");
    if (this.localStream) {
      var track = this.localStream.getTracks()[0]; // if only one media track
      track.stop();
    }

    //closing consumer and producer transports
    for (let transport of this.state.producerTransports) {
      transport.close();
    }

    for (let transport of this.state.consumerTransports) {
      transport.close();
    }
  }

  async componentDidMount() {
    await this.props.clearChatBoxes();
    this.props.fetchExam(this.room_id, this.learner_id);
    this.props.socket.request = socketUtil.promise(this.props.socket);

    const data = await this.props.socket.request("getRouterRtpCapabilities", {
      room: this.room_id,
      user_id: this.props.kc.tokenParsed.sub,
      role: "proctor",
      username: this.props.kc.tokenParsed.preferred_username,
      first_name: this.props.kc.tokenParsed.given_name,
      last_name: this.props.kc.tokenParsed.family_name,
    });

    const learner = await this.props.socket.request("joinCheckIn", {
      room: this.room_id,
      learner_id: this.learner_id,
      role: "proctor",
      user_id: this.props.kc.tokenParsed.sub,
    });

    this.setState({ learnersUsername: learner.username });
    this.setState({ learnersFirstName: learner.first_name });
    this.setState({ learnersLastName: learner.last_name });

    await this._loadDevice(data);
    await this.addUserToSession(learner);

    //subscribing to learner audio/video
    await this._subscribe(MEDIA_TYPE_AUDIO);
    await this._subscribe(MEDIA_TYPE_VIDEO);

    //publishing proctor audio/video
    await this._publish(MEDIA_TYPE_AUDIO);
    await this._publish(MEDIA_TYPE_VIDEO);

    this.props.socket.on("learnerLeftCheckIn", () => {
      this.setState({ learnerLeft: true });
    });

    this.props.socket.on("learnerRejoined", () => {
      this.setState({ learnerRejoined: true, learnerLeft: false });
    });

    this.props.socket.on("usertyping", (data) => {
      this.handleUserTyping(data);
    });

    this.props.socket.on("message", (msg) => {
      this.props.addComment({
        room: msg.room,
        text: msg.text,
        timestamp: moment.now(),
        author: `${msg.first_name} ${msg.last_name}`,
        user_id: msg.from,
      });
      this.props.updateNewMessage(true);
    });
  }

  handleUserTyping(data) {
    if (data.is_typing) {
      this.setState({ usertyping: true });
    } else {
      this.setState({ usertyping: false });
    }
  }

  async _loadDevice(routerRtpCapabilities) {
    try {
      this.device = new mediasoup.Device();
      await this.device.load({ routerRtpCapabilities });
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  /**
   * Subscribe to remote video/audio
   * @param {String} mediaType
   */
  async _subscribe(mediaType) {
    try {
      const data = await this.props.socket.request("createConsumerTransport", {
        forceTcp: false,
        room: this.room_id,
        kind: mediaType,
        user_id: this.props.kc.tokenParsed.sub,
        consuming_id: this.learner_id,
      });

      const transport = this.device.createRecvTransport(data);

      this.setState({
        consumerTransports: this.state.consumerTransports.concat(transport),
      });

      transport.on("connect", ({ dtlsParameters }, callback, errback) => {
        this.props.socket
          .request("connectConsumerTransport", {
            transportId: transport.id,
            dtlsParameters,
            room: this.room_id,
            kind: mediaType,
            user_id: this.props.kc.tokenParsed.sub,
            consuming_id: this.learner_id,
          })
          .then(callback)
          .catch((err) => {
            Sentry.captureException(err);
            errback(err);
          });
      });

      const stream = await this._consume(transport, mediaType);

      if (stream) {
        switch (mediaType) {
          case MEDIA_TYPE_VIDEO:
            this.remoteVideo.srcObject = stream;
            break;
          case MEDIA_TYPE_AUDIO:
            stream.getTracks()[0].enabled = true;
            this.remoteAudio.srcObject = stream;
            break;
        }
      }

      transport.on("connectionstatechange", (state) => {
        switch (state) {
          case "failed":
            transport.close();
            throw new Error(`FAILED TO CONSUME ${mediaType}`);
          default:
            break;
        }
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }
  /**
   * Consume remote Transport
   * @param {Object} transport
   * @param {String} mediaType
   */
  async _consume(transport, mediaType) {
    const { rtpCapabilities } = this.device;
    const data = await this.props.socket.request("consume", {
      rtpCapabilities,
      room: this.room_id,
      kind: mediaType,
      consuming_id: this.learner_id,
      consumer_id: this.props.kc.tokenParsed.sub,
    });

    const { producerId, id, kind, rtpParameters } = data;
    const codecOptions = {};

    const consumer = await transport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    });

    const stream = new MediaStream();
    stream.addTrack(consumer.track);
    return stream;
  }

  /**
   * Publish proctors video/audio
   * @param {String} mediaType
   */
  async _publish(mediaType) {
    try {
      const data = await this.props.socket.request("createProducerTransport", {
        forceTcp: false,
        rtpCapabilities: this.device.rtpCapabilities,
        room: this.room_id,
        role: "proctor",
        kind: mediaType,
        user_id: this.props.kc.tokenParsed.sub,
      });

      const transport = this.device.createSendTransport(data);
      this.setState({
        producerTransports: this.state.producerTransports.concat(transport),
      });

      transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        this.props.socket
          .request("connectProducerTransport", {
            dtlsParameters,
            room: this.room_id,
            kind: mediaType,
            user_id: this.props.kc.tokenParsed.sub,
          })
          .then(callback)
          .catch((err) => {
            Sentry.captureException(err);
            errback(err);
          });
      });

      transport.on(
        "produce",
        async ({ kind, rtpParameters }, callback, errback) => {
          try {
            const { id } = await this.props.socket.request("produce", {
              transportId: transport.id,
              kind,
              rtpParameters,
              room: this.room_id,
              role: "proctor",
              user_id: this.props.kc.tokenParsed.sub,
              learner_id: this.learner_id,
              type: mediaType,
            });
            callback({ id });
          } catch (e) {
            Sentry.captureException(e);
            errback(e);
          }
        }
      );

      transport.on("connectionstatechange", (state) => {
        switch (state) {
          case "failed":
            transport.close();
            throw new Error(`FAILED TO PRODUCE ${mediaType}`);
          default:
            break;
        }
      });

      this.localStream = await this._getUserMedia(transport, mediaType);
      if (mediaType === MEDIA_TYPE_VIDEO) {
        this.localVideo.current.srcObject = this.localStream;
        this.setState({ alreadyConnected: true });
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  /**
   * Produce proctors video/audio
   * @param {Object} transport
   * @param {String} kind
   */
  async _getUserMedia(transport, kind) {
    if (!this.device.canProduce(kind)) {
      throw new Error(`error: cannot produce ${kind}`);
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: kind === MEDIA_TYPE_VIDEO,
        audio: kind === MEDIA_TYPE_AUDIO,
      });
      const track =
        kind === MEDIA_TYPE_VIDEO
          ? stream.getVideoTracks()[0]
          : stream.getAudioTracks()[0];
      const params = { track };
      await transport.produce(params);
      return stream;
    } catch (e) {
      this.setState({ webcamNotReadable: true });
      Sentry.captureException(e);
    }
  }
  joinCheckIn() {
    this.props.socket.emit("proctorJoinedCheckIn", this.offer);
  }

  handleAccept() {
    this.props.socket.emit("learnerVerified", {
      room: this.room_id,
      exam_id: this.props.exam.id,
      learner_id: this.learner_id,
      proctor_id: this.props.kc.tokenParsed.sub,
    });
  }
  handleDecline() {
    this.props.socket.emit("learnerDeclined", {
      room: this.room_id,
      learner_id: this.learner_id,
    });
  }
  async handleLearnerRejoined() {
    //learner rejoined checkin so resubscribe
    await this._subscribe(MEDIA_TYPE_AUDIO);
    await this._subscribe(MEDIA_TYPE_VIDEO);
  }

  addUserToSession(learner) {
    return new Promise((resolve, reject) => {
      const comments = learner.messages.map((message) => {
        return {
          room: message.room,
          text: message.text,
          timestamp: message.timestamp,
          author: `${message.first_name} ${message.last_name}`,
          user_id: learner.id,
        };
      });

      this.props.addChatBox({
        socket_id: learner.socket_id,
        username: learner.username,
        user_id: learner.id,
        assistance_requested: false,
        unreadMessages: learner.messages.length,
        comments,
      });

      resolve();
    });
  }

  render() {
    return (
      <Container>
        <MainMenu />
        <Header as="h2">
          <Header.Content>
            {this.props.exam ? this.props.exam.title : null}
            <Header.Subheader>Checkin Process</Header.Subheader>
          </Header.Content>
        </Header>
        <Header size="medium">
          Name: {this.state.learnersFirstName} {this.state.learnersLastName}{" "}
        </Header>
        <Header size="medium">Username: {this.state.learnersUsername}</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={10}>
              <Message
                positive
                hidden={!this.state.learnerRejoined}
                onDismiss={() => this.setState({ learnerRejoined: false })}
              >
                <Message.Header onClick={this.handleLearnerRejoined}>
                  {this.props.t("Checkin.learnerRejoinedHeader")}
                </Message.Header>
                <p>{this.props.t("Checkin.learnerRejoinedMessage")}</p>
              </Message>
              <Message
                negative
                hidden={!this.state.learnerLeft}
                onDismiss={() => this.setState({ learnerLeft: false })}
              >
                <Message.Header>
                  {this.props.t("Checkin.learnerLeftHeader")}
                </Message.Header>
                <p>{this.props.t("Checkin.learnerLeftMessage")}</p>
              </Message>
              <Message
                negative
                hidden={!this.state.webcamNotReadable}
                onDismiss={() => this.setState({ webcamNotReadable: false })}
              >
                <Message.Header>
                  {this.props.t("Checkin.webcamNotReadableHeader")}
                </Message.Header>
                <p>{this.props.t("Checkin.webcamNotReadableMessage")}</p>
              </Message>
              <Segment>
                <video
                  style={{ maxWidth: "100%" }}
                  autoPlay
                  poster={String(poster)}
                  ref={(remoteVideo) => {
                    this.remoteVideo = remoteVideo;
                  }}
                />
                <audio
                  ref={(remoteAudio) => {
                    this.remoteAudio = remoteAudio;
                  }}
                  volume="true"
                  autoPlay
                />
              </Segment>
              <Segment>
                <video
                  style={{ maxWidth: "100%" }}
                  autoPlay
                  poster={String(poster)}
                  ref={this.localVideo}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={6} className="check-sidebar">
              <Button.Group>
                <NavLink to="/scheduled-exams">
                  <Button onClick={this.handleAccept} positive>
                    {this.props.t("general.accept")}
                  </Button>
                </NavLink>
                <Button.Or />
                <NavLink to="/scheduled-exams">
                  <Button negative onClick={this.handleDecline}>
                    {this.props.t("general.decline")}
                  </Button>
                </NavLink>
              </Button.Group>
              {this.state.usertyping ? (
                <h5
                  id="typingNotice"
                  style={{ width: "90%", margin: "25px auto 0px" }}
                >
                  {this.state.learnersUsername}{" "}
                  {this.props.t("Checkin.userTyping")}
                </h5>
              ) : null}
              {this.props.chatBoxes.map((data, i) => {
                return (
                  <Chat
                    key={`${data.user_id}_chat_box`}
                    socket={this.props.socket}
                    socket_id={data.socket_id}
                    room_id={this.room_id}
                    user_id={data.user_id}
                    comments={data.comments}
                  />
                );
              })}
              <Segment>
                <Image
                  src={
                    `${process.env.REACT_APP_API_ADDRESS}/api/images/profile/` +
                    `${this.learner_id}_user_profile_img.png?token=${this.props.kc.token}`
                  }
                  size="small"
                  wrapped
                />
                <Header>{this.props.t("Checkin.checkinScript")}</Header>
                {this.props.exam
                  ? this.props.exam.rules || this.props.t("Checkin.noRules")
                  : ""}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam.exam,
      chatBoxes: state.chat.chatBoxes,
    }),
    {
      fetchExam,
      addChatBox,
      clearChatBoxes,
      updateChatBoxes,
      addComment,
      updateNewMessage,
      updateMessage,
      updateShowMessage,
    }
  )(withRouter(Checkin))
);
