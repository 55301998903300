import React from "react";
import { Feed } from "semantic-ui-react";
var moment = require("moment");

class SingleNote extends React.Component {
  render() {
    const { note, user, timestamp } = this.props;

    return (
      <Feed.Event>
        <Feed.Content>
          <Feed.Summary>
            <Feed.User>{user}</Feed.User> {note}
            <Feed.Date>
              {moment.unix(timestamp / 1000).format("DD MMM YYYY hh:mm a")}
            </Feed.Date>
          </Feed.Summary>
        </Feed.Content>
      </Feed.Event>
    );
  }
}

export default SingleNote;
