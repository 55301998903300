import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Segment, Grid } from "semantic-ui-react";
import ProctoringSession from "./ProctoringSession";
import Exam from "./Exam";
import Dashboard from "./Dashboard";
import ClientDownload from "./ClientDownload";
import BookExam from "./BookExam";
import StudentDashboard from "./StudentDashboard";
import Logout from "../components/Logout";
import Notification from "./../components/Notification";
import AccountDetails from "./AccountDetails";
import ExamSchedule from "./ExamSchedule";
import PendingRequests from "./PendingRequests";
import Learners from "./Learners";
import LearnerBookings from "./LearnerBookings";
import RescheduleExam from "./RescheduleExam";
import Settings from "./Settings";
import Replay from "./Replay";
import Checkin from "./Checkin";
import {
  addNotification,
  dismissNotification,
} from "./../reducers/notification";
import { updateKeycloak } from "./../reducers/keycloak";
import "../App.css";
import io from "socket.io-client";
import FixedMenu from "./../components/FixedMenu";
import { withTranslation } from "react-i18next";
import RequireRBAC from "../components/RequireRBAC";
import RequireProfileImage from "../components/RequireProfileImage";

class App extends Component {
  constructor(props) {
    super(props);

    this.props.updateKeycloak({
      tokenParsed: this.props.keycloak.tokenParsed,
      token: this.props.keycloak.token,
      realmAccess: this.props.keycloak.realmAccess,
      subject: this.props.keycloak.subject,
    });

    this.socket = io(process.env.REACT_APP_WEBSOCKET_DB, {
      query: `bearer=${this.props.keycloak.token}`,
    });
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.state = {
      active: "dashboard",
    };
  }
  componentDidMount() {
    this.socket.on("learnerCheckIn", (message) => {
      const path = window.location.href.split("/");
      const user_id = path.slice(-1)[0];
      const room_id = path.slice(-2)[0];
      const currentPath = path.slice(-3)[0];

      const link = `/checkIn/${message.room}/${message.user_id}`;
      if (link !== `/${currentPath}/${room_id}/${user_id}`) {
        if (
          this.props.keycloak.tokenParsed.realm_access.roles.indexOf(
            "proctor"
          ) > -1
        ) {
          this.props.addNotification({
            message: `${message.username} is ready to check in.`,
            title: "Check In",
            link: link,
          });
        }
      }
    });

    this.socket.on("removeCheckInNotification", (message) => {
      this.props.notification.notifications.forEach((data) => {
        if (data.message === `${message} is ready to check in.`) {
          this.props.dismissNotification(data.id);
        }
      });
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    return !("notification" in nextProps);
  }
  handleMenuClick(active) {
    this.setState({ active });
  }
  render() {
    let isProctor =
      this.props.keycloak.tokenParsed.realm_access.roles.indexOf("proctor") >
      -1;

    return (
      <Router>
        <div>
          <Grid>
            <Grid.Row style={{ paddingBottom: "0em" }}>
              <FixedMenu
                name={this.props.keycloak.tokenParsed.name}
                logout={this.props.keycloak.logout}
                setActiveMenuItem={this.handleMenuClick}
                active={this.state.active}
                lang={this.state.lang}
              />
              <Segment
                inverted
                textAlign="center"
                style={{
                  minHeight: 51,
                  padding: "1em 0em",
                  background: "white",
                }}
                vertical
              />
            </Grid.Row>
            <div className="grad-bar"></div>
            <Grid.Row>
              <Notification />
            </Grid.Row>
          </Grid>
          <Routes>
            <Route
              path="/proctoring/:room_id"
              element={
                <RequireRBAC allowedRoles={["can-view-proctoring"]}>
                  <ProctoringSession socket={this.socket} />
                </RequireRBAC>
              }
            />
            <Route
              path="/exam"
              element={
                <RequireRBAC allowedRoles={["proctor", "institution"]}>
                  <Exam />
                </RequireRBAC>
              }
            />
            <Route
              path="/scheduled-exams"
              element={
                <RequireRBAC allowedRoles={["proctor", "institution"]}>
                  <ExamSchedule socket={this.socket} />
                </RequireRBAC>
              }
            />
            <Route
              path="/exams/book"
              element={
                <RequireRBAC allowedRoles={["learner"]}>
                  <RequireProfileImage>
                    <BookExam />
                  </RequireProfileImage>
                </RequireRBAC>
              }
            />
            <Route
              path="/pending-requests"
              element={
                <RequireRBAC allowedRoles={["proctor", "institution"]}>
                  <PendingRequests />
                </RequireRBAC>
              }
            />
            <Route
              path="/learner-details/:user_id"
              element={
                <RequireRBAC allowedRoles={["proctor", "institution"]}>
                  <LearnerBookings />
                </RequireRBAC>
              }
            />
            <Route
              path="/learner-details"
              element={
                <RequireRBAC allowedRoles={["proctor", "institution"]}>
                  <Learners />
                </RequireRBAC>
              }
            />
            <Route
              path="/schedule"
              element={
                <RequireRBAC allowedRoles={["proctor", "institution"]}>
                  <RescheduleExam />
                </RequireRBAC>
              }
            />
            <Route
              path="/settings"
              element={
                <RequireRBAC allowedRoles={["proctor", "institution"]}>
                  <Settings />
                </RequireRBAC>
              }
            />
            <Route
              path="/replay/:session_id"
              element={
                <RequireRBAC allowedRoles={["proctor", "institution"]}>
                  <Replay />
                </RequireRBAC>
              }
            />
            <Route
              path="/checkIn/:room_id/:learner_id"
              element={
                <RequireRBAC allowedRoles={["proctor", "institution"]}>
                  <Checkin socket={this.socket} />
                </RequireRBAC>
              }
            />
            <Route path="/download" element={<ClientDownload />} />
            <Route path="/account-details" element={<AccountDetails />} />
            <Route
              path="/logout"
              element={<Logout loadingText={this.props.t("App.loggingOut")} />}
            />
            {isProctor ? (
              <Route path="/" element={<Dashboard />} />
            ) : (
              <Route path="/" element={<StudentDashboard />} />
            )}
          </Routes>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.notification,
    kc: state.keycloak,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    addNotification,
    dismissNotification,
    updateKeycloak,
  })(App)
);
