import React from "react";
import { withTranslation } from "react-i18next";

import BasicTable from "./Table/BasicTable";
import {
  ListRenderer,
  CheckMarkRenderer,
  DropdownRenderer,
} from "./Table/CustomComponents";

const CouponTable = (props) => (
  <BasicTable
    columns={[
      {
        headerName: props.t("general.name"),
        field: "coupon_name",
        cellStyle: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
        flex: 2,
      },
      {
        headerName: props.t("CouponTable.value"),
        field: "coupon_value",
        valueGetter: (row) =>
          `${row.data.coupon_value}${row.data.coupon_value_type}`,
        cellStyle: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
      },
      {
        field: "exams",
        headerName: props.t("CouponTable.exams"),
        cellRenderer: ListRenderer,
        valueGetter: (row) => {
          if (
            row.data.coupon_exams !== null &&
            row.data.coupon_exams.length > 0
          ) {
            return props.allExams
              .filter((exam) => {
                return row.data.coupon_exams.includes(exam.id);
              })
              .map((exam) => ({
                key: `exam_coupon_${exam.id}`,
                value: exam.title,
              }));
          }
          return [{ key: `exam_all`, value: "All" }];
        },
        cellStyle: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
        flex: 2,
        filter: true,
      },
      {
        headerName: props.t("CouponTable.maxUses"),
        field: "coupon_max_uses",
        cellStyle: {
          textAlign: "center",
          verticalAlign: "middle",
          alignSelf: "center",
        },
        flex: 1,
      },
      {
        headerName: props.t("CouponTable.timesUsed"),
        field: "coupon_times_used",
        cellStyle: {
          textAlign: "center",
          verticalAlign: "middle",
          alignSelf: "center",
        },
        flex: 1,
      },
      {
        field: "coupon_availability",
        headerName: props.t("general.active"),
        cellRenderer: CheckMarkRenderer,
        cellStyle: {
          textAlign: "center",
          verticalAlign: "middle",
          alignSelf: "flex-end",
        },
        flex: 1,
      },
      {
        headerName: props.t("general.actions"),
        cellRenderer: DropdownRenderer,
        cellRendererParams: {
          text: props.t("general.view"),
        },
        valueGetter: (row) => {
          return [
            {
              text: props.t("general.edit"),
              onClick: () => props.handleUpdateCoupon(row.data.id),
            },
            {
              text:
                row.data.coupon_availability === true
                  ? props.t("general.deactivate")
                  : props.t("general.activate"),
              onClick: () => props.handleCouponStatus(row.data.id),
            },
          ];
        },
        cellStyle: {
          overflow: "inherit",
          textAlign: "center",
          alignSelf: "flex-end",
        },
        flex: 2,
      },
    ]}
    data={props.data}
  />
);

export default withTranslation()(CouponTable);
