import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Header,
  Grid,
  Divider,
  Icon,
  Message,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { fetchScheduledBookings } from "../../reducers/booking";
import ExamBlock from "./ExamBlock";
import { withTranslation, Trans } from "react-i18next";
import { format } from "date-fns";
import { TZDate } from "@date-fns/tz";
import parseTimezone from "../../lib/helperServices";

function ScheduledExams({ bookings, kc, t, fetchScheduledBookings }) {
  useEffect(() => {
    fetchScheduledBookings(kc.tokenParsed.sub);
  }, [kc.tokenParsed.sub]);

  let timezone = parseTimezone(
    kc.tokenParsed.timezone,
    kc.tokenParsed.province
  );
  let timezone_array = kc.tokenParsed.timezone.split("");
  const dateFormat =
    localStorage.getItem("lang") === "en"
      ? "MMMM do yyyy - h:mm a"
      : "MMMM do yyyy - HH:mm";

  return (
    <div>
      <Container style={{ marginTop: "2em" }}>
        <div>
          <Grid centered>
            <Grid.Row>
              <Grid.Column>
                <Header
                  style={{
                    textTransform: "uppercase",
                    fontSize: "55px",
                    textAlign: "center",
                  }}
                  as="h1"
                >
                  {t("ScheduledExams.welcome")}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <div className="home-buttons ui two column centered grid">
                <div className="four column centered row">
                  <Grid.Column textAlign="center">
                    <Button as={NavLink} to="/download" positive>
                      {t("ScheduledExams.downloadApplication")}
                      <br />
                      <Icon name="download" />
                    </Button>
                  </Grid.Column>
                  <Grid.Column textAlign="center">
                    <Button as={NavLink} to="/exams/book/" positive>
                      {t("ScheduledExams.bookExam")}
                      <br />
                      <Icon name="calendar plus" />
                    </Button>
                  </Grid.Column>
                </div>
              </div>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <Divider className="home-divider" />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={4}>
                <Header textAlign="center" as="h1">
                  {t("ScheduledExams.header")}
                </Header>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <Divider className="home-divider" />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid>
            <Grid.Row>
              <Message className="home-message">
                <Trans i18nKey="ScheduledExams.downloadInstructions">
                  Within 30 minutes of your exam start time, please download the{" "}
                  <NavLink to="/download">application</NavLink>. Once the
                  download is complete, please enter your login credentials
                  again and select the exam by clicking on the title. This will
                  notify the Proctor you are ready to begin. The Proctor will
                  walk you through the check-in process.
                  <strong>
                    PLEASE NOTE: Ensure that you have downloaded the latest
                    version of the eProctor application client or you may
                    experience technical difficulties and not have access to all
                    new features. Should you have questions about what version
                    numbers, please contact support.
                  </strong>
                </Trans>
              </Message>
            </Grid.Row>
            <Grid.Row>
              {bookings.map((data, i) => {
                if (data.user_id === kc.tokenParsed.sub) {
                  return (
                    <Grid.Column
                      key={`${i}_scheduled_exam`}
                      mobile={16}
                      tablet={10}
                      computer={5}
                    >
                      <ExamBlock
                        timezone={`${timezone_array[0]}${timezone_array[2]}`}
                        past={false}
                        date={format(
                          new TZDate(data.booking_date, timezone),
                          dateFormat
                        )}
                        status={data.approved}
                        title={
                          data.exam.title !== undefined ? data.exam.title : ""
                        }
                      />
                    </Grid.Column>
                  );
                } else {
                  return null;
                }
              })}
            </Grid.Row>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default withTranslation()(
  connect(
    (state) => ({
      bookings: state.bookings.scheduledBookings,
      kc: state.keycloak,
    }),
    { fetchScheduledBookings }
  )(ScheduledExams)
);
