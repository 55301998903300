import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { format } from "date-fns";

import DataTable, { SimpleDropdownFilter } from "./Table/DataTable";
import {
  ListRenderer,
  DropdownRenderer,
  ColorTextRenderer,
} from "./Table/CustomComponents";
import {
  fetchExams,
  fetchExamById,
  deleteExamCreator,
  changeModal,
  updateExamToDelete,
  updateLimitNotice,
  updateRules,
  updateStudentRules,
  updateExamToUpdate,
  setIsUpdating,
  updateExamName,
  updateDuration,
  sendMail,
  updateOpenAddExam,
  updateCost,
  updateDescription,
  updatePaymentRequired,
  updateCostDisabled,
  updateDaysAvailable,
  updateAvailableFromTime,
  updateAvailableToTime,
  updateClient,
  updateCapacity,
  updateLimit,
  updateFileUploadRequired,
  updateFileUploadDescription,
} from "../reducers/exam";

const textFilterParams = {
  filterOptions: ["contains"],
  trimInput: true,
  debounceMs: 1000,
  maxNumConditions: 1,
};

function getFilterParams(filterValues) {
  const params = {
    title: filterValues.title?.filter,
    clients: filterValues.clients?.filter,
    status: filterValues.status,
  };

  return params;
}

class ExamTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.submitDelete = this.submitDelete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleUpdateExam = this.handleUpdateExam.bind(this);

    this.dataTable = React.createRef();

    this.columns = [
      {
        headerName: props.t("general.title"),
        field: "title",
        filter: "agTextColumnFilter",
        filterParams: textFilterParams,
        flex: 2,
      },
      {
        headerName: this.props.t("general.duration"),
        field: "duration",
        filter: false,
        flex: 1,
      },
      {
        headerName: this.props.t("Exam.cost"),
        field: "cost",
        filter: false,
        flex: 1,
      },
      {
        headerName: this.props.t("ExamTable.learningInstitutions"),
        field: "clients",
        valueGetter: (row) => {
          if (row.data?.clients !== null && row.data?.clients?.length > 0) {
            return row.data?.clients.map((client) => ({
              key: `learning_instituations_${client.id}`,
              value: client.organization_name,
            }));
          }
          return [];
        },
        cellRenderer: ListRenderer,
        cellStyle: {
          verticalAlign: "middle",
          alignSelf: "center",
        },
        filter: "agTextColumnFilter",
        filterParams: textFilterParams,
        flex: 2,
      },
      {
        headerName: this.props.t("ExamTable.added"),
        field: "added",
        filter: false,
        valueGetter: (row) =>
          row.data?.createdAt
            ? format(new Date(row.data?.createdAt), "MMMM do, yyyy")
            : "",
        flex: 2,
      },
      {
        headerName: this.props.t("general.status"),
        cellRenderer: ColorTextRenderer,
        cellRendererParams: (row) =>
          row.data?.deleted === null ? "green" : "red",
        field: "status",
        sortable: true,
        filter: SimpleDropdownFilter,
        filterParams: {
          options: [
            { value: "active", text: props.t("general.active") },
            { value: "deactivated", text: props.t("general.deactivated") },
            { value: "all", text: props.t("general.all") },
          ],
          text: "Status",
        },
        valueGetter: (row) => {
          if (row.data) {
            return row.data.deleted === null
              ? this.props.t("general.active")
              : this.props.t("general.deactivated");
          }
        },
        flex: 1,
      },
      {
        headerName: this.props.t("ExamTable.bookingLimit"),
        field: "limit",
        filter: false,
        valueGetter: (row) => {
          return row.data?.limit;
        },
        flex: 1,
      },
      {
        headerName: this.props.t("general.actions"),
        cellRenderer: DropdownRenderer,
        cellRendererParams: {
          text: this.props.t("general.edit"),
        },
        valueGetter: (row) => {
          if (row.data) {
            return [
              {
                text: this.props.t("general.edit"),
                onClick: () => {
                  this.handleUpdateExam(row.data.id);
                  this.props.updateOpenAddExam();
                },
              },
              {
                text: this.props.t("general.deactivate"),
                onClick: () =>
                  this.handleDeleteExam(row.data.id, row.data.title),
              },
            ];
          }
        },
        cellStyle: {
          overflow: "inherit",
          textAlign: "center",
          alignSelf: "flex-end",
        },
        flex: 2,
        filter: false,
        sortable: false,
      },
    ];
  }
  handleUpdateExam(id) {
    this.props.fetchExamById(id);
    this.props.updateExamToUpdate(id);
    this.props.setIsUpdating(true);
  }
  handleDeleteExam(id, title) {
    this.props.updateExamToDelete({ id, title });
    this.props.changeModal();
  }
  submitDelete() {
    this.props.deleteExamCreator(this.props.exam.examToDelete);
    this.props.changeModal();
    this.dataTable.current.refresh();
  }
  closeModal() {
    this.props.changeModal();
  }
  refreshTable() {
    this.dataTable.current.refresh();
  }
  render() {
    return (
      <div>
        <Modal
          size="small"
          className="modalHOTFIX"
          open={this.props.exam.open}
          closeIcon
          onClose={() => {
            this.props.changeModal();
          }}
        >
          <Modal.Header>{this.props.t("ExamTable.deleteExam")}</Modal.Header>
          <Modal.Content>
            <p>
              {this.props.t("ExamTable.confirmDelete")}
              {this.props.exam.examToDelete.title}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.closeModal}>
              {this.props.t("general.no")}
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content={this.props.t("general.yes")}
              onClick={this.submitDelete}
            />
          </Modal.Actions>
        </Modal>
        <DataTable
          ref={this.dataTable}
          columnDefs={this.columns}
          url={`${process.env.REACT_APP_API_ADDRESS}/api/examinations/table`}
          getFilterParams={getFilterParams}
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect((state) => ({ exam: state.exam }), {
    fetchExams,
    fetchExamById,
    deleteExamCreator,
    updateLimitNotice,
    updateRules,
    updateStudentRules,
    changeModal,
    updateExamToDelete,
    updateExamToUpdate,
    setIsUpdating,
    updateExamName,
    updateDuration,
    sendMail,
    updateOpenAddExam,
    updateCost,
    updateDescription,
    updatePaymentRequired,
    updateCostDisabled,
    updateDaysAvailable,
    updateAvailableFromTime,
    updateAvailableToTime,
    updateClient,
    updateCapacity,
    updateLimit,
    updateFileUploadRequired,
    updateFileUploadDescription,
  })(ExamTable)
);
