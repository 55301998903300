import React from "react";
import { Header, Segment, Feed } from "semantic-ui-react";
import SingleEvent from "../components/SingleEvent";

const EventLog = (props) => (
  <div>
    <Header as="h2" attached="top">
      {props.header}
    </Header>
    <Segment attached>
      <Feed style={{ height: 100, overflowY: "scroll" }}>
        {props.events.map((data, i) => (
          <SingleEvent key={`${data.session_id}_event_${i}`} {...data} />
        ))}
      </Feed>
    </Segment>
  </div>
);

export default EventLog;
