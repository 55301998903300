import React from "react";
import { Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { format } from "date-fns";

import BasicTable from "./Table/BasicTable";
import StatusText from "./StatusText";
import DownloadButton from "./DownloadButton";

function StatusRenderer(params) {
  return <StatusText status={params.value} />;
}

function RecordingsRenderer(row) {
  if (row.value.downloadAvailable) {
    return (
      <DownloadButton
        url={`${process.env.REACT_APP_API_ADDRESS}/api/recordings/download/${row.data.rec_id}`}
        text={row.value.text}
      />
    );
  } else {
    if (row.value.cb !== null) {
      return <Button onClick={row.value.cb}>{row.value.text}</Button>;
    } else {
      return row.value.text;
    }
  }
}

const LearnersBookingsTable = (props) => (
  <BasicTable
    columns={[
      {
        headerName: props.t("general.exam"),
        field: "Exam",
        filter: true,
        flex: 2,
      },
      {
        headerName: props.t("general.date"),
        field: "Date",
        valueGetter: (row) =>
          format(new Date(row.data.Date), "MMMM do yyyy, HH:mm a"),
        filter: false,
        flex: 2,
      },
      {
        headerName: props.t("general.status"),
        field: "Status",
        filter: true,
        cellRenderer: StatusRenderer,
        flex: 1,
      },
      {
        headerName: props.t("general.error"),
        valueGetter: (row) => row.data.Error,
        tooltipValueGetter: (row) => row.data.Error,
        headerTooltip: "Error",
        flex: 2,
      },
      {
        headerName: props.t("LearnerBookings.recordings"),
        field: "Recordings",
        filter: false,
        flex: 2,
        valueGetter: (row) => {
          if (row.data.SessionCount > 0) {
            if (row.data.rec_id === null) {
              return {
                text: props.t("general.request"),
                cb: () =>
                  props.handleRequestRecordings({
                    booking_id: row.data.booking_id,
                    exam: row.data.Exam,
                    date: row.data.Date,
                  }),
                downloadAvailable: false,
              };
            } else if (row.data.Recordings === 0) {
              return {
                text: props.t("general.pending"),
                cb: null,
                downloadAvailable: false,
              };
            } else if (row.data.Recordings === 1) {
              return {
                text: props.t("general.download"),
                cb: null,
                downloadAvailable: true,
              };
            } else {
              return {
                text: props.t("LearnerBookings.requestDownloadAgain"),
                cb: () =>
                  props.handleRequestRecordings({
                    booking_id: row.data.booking_id,
                    exam: row.data.Exam,
                    date: row.data.Date,
                  }),
                downloadAvailable: false,
              };
            }
          }

          return {
            text: "",
            cb: null,
            downloadAvailable: false,
          };
        },
        cellRenderer: RecordingsRenderer,
      },
    ]}
    data={props.data}
  />
);

export default withTranslation()(LearnersBookingsTable);
