import axios from "axios";

/**
 * Update a Users credentials.
 * @param user
 * @returns {Promise<any>}
 */
export const updateUser = (user) => {
  return axios
    .post(
      process.env.REACT_APP_API_ADDRESS + "/api/updateUser",
      {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        timezone: user.timezone,
        learningInstitution: user.learningInstitution
          ? user.learningInstitution
          : 1,
        province: user.province,
        profile_image: user.profile_image,
        street: user.streetAddress,
        postal_code: user.postalCode,
        city: user.city,
        phone: user.phone,
        birthday: user.birthday,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
};

export const getAllLearners = () => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/allLearners")
    .then((response) => response.data.learners)
    .catch((error) => console.log(error));
};

export const getUserById = (user_id) => {
  return axios
    .get(process.env.REACT_APP_API_ADDRESS + "/api/user/" + user_id)
    .then((response) => response)
    .catch((error) => console.log(error));
};
