import { getAllUsers } from "./../lib/userServices";
import { listLearnerSessions, getSessionById } from "./../lib/sessionServices";
import { getAllBookingsByUserId } from "./../lib/bookingServices";

const initState = {
  allLearners: [],
  learnerSessions: [],
  sessionToReplay: {},
  learner: "",
  selected: null,
};

const LOAD_LEARNERS = "LOAD_LEARNERS";
const LOAD_SELECTED_LEARNER = "LOAD_SELECTED_LEARNER";
const LOAD_SESSIONS = "LOAD_SESSIONS";
const UPDATE_SESSION_TO_REPLAY = "UPDATE_SESSION_TO_REPLAY";

export const loadLearners = (learners) => ({
  type: LOAD_LEARNERS,
  payload: learners,
});
export const loadSelectedLearner = (selected) => ({
  type: LOAD_SELECTED_LEARNER,
  payload: selected,
});
export const loadSessions = (sessions) => ({
  type: LOAD_SESSIONS,
  payload: sessions,
});
export const updateSessionToReplay = (session) => ({
  type: UPDATE_SESSION_TO_REPLAY,
  payload: session,
});

export const fetchLearners = () => {
  return (dispatch) => {
    getAllUsers().then((learners) => dispatch(loadLearners(learners)));
  };
};

export const fetchLearnersSessions = () => {
  return (dispatch) => {
    listLearnerSessions().then((sessions) => dispatch(loadSessions(sessions)));
  };
};

export const fetchSessionById = (guid) => {
  return (dispatch) => {
    getSessionById(guid).then((session) =>
      dispatch(updateSessionToReplay(session))
    );
  };
};

export const fetchLearnerWithBookings = (uuid) => async (dispatch) => {
  const bookings = await getAllBookingsByUserId(uuid);
  dispatch(loadSelectedLearner(bookings));
};

export default (state = initState, action) => {
  switch (action.type) {
    case LOAD_LEARNERS:
      return { ...state, allLearners: action.payload };
    case LOAD_SESSIONS:
      let sessions = [];
      action.payload?.forEach((data) => {
        let session = {
          start_time: data.start_time,
          end_time: data.end_time,
          session_guid: data.session_guid,
          exam_title: data.examinationId.title,
        };
        if (sessions[data.user_id]) {
          sessions[data.user_id].push(session);
        } else {
          sessions[data.user_id] = [session];
        }
      });
      return { ...state, learnerSessions: sessions };
    case LOAD_SELECTED_LEARNER:
      return { ...state, selected: action.payload };
    case UPDATE_SESSION_TO_REPLAY:
      return { ...state, sessionToReplay: action.payload };
    default:
      return state;
  }
};
