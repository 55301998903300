import React, { Component } from "react";
import {
  Button,
  Modal,
  Form,
  Grid,
  Input,
  Checkbox,
  Message,
  Header,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import {
  fetchClients,
  updateEditModal,
  updateStatusModal,
  updateClientStatus,
  updateClientAvailability,
  changeClientStatus,
  updateClientName,
  updateClientContact,
  updateClientEmail,
  setIsUpdating,
  updateExistingClient,
  saveClient,
} from "../reducers/clients";
import BasicTable from "./Table/BasicTable";
import { DropdownRenderer, CheckMarkRenderer } from "./Table/CustomComponents";

class InstitutionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.clients.clients,
      status: "",
      nameError: false,
      error: false,
    };
    this.updateClientName = this.updateClientName.bind(this);
    this.updateClientContact = this.updateClientContact.bind(this);
    this.updateClientEmail = this.updateClientEmail.bind(this);
    this.updateClientAvailability = this.updateClientAvailability.bind(this);
    this.submitStatusUpdate = this.submitStatusUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  componentDidMount() {
    this.props.fetchClients();
  }
  updateClientName(value) {
    this.props.updateClientName(value);
  }
  updateClientContact(value) {
    this.props.updateClientContact(value);
  }
  updateClientEmail(value) {
    this.props.updateClientEmail(value);
  }
  updateClientAvailability(value) {
    if (!value) {
      this.props.clients.online = false;
    } else {
      this.props.clients.online = true;
    }
    this.props.updateClientAvailability(value);
  }
  handleAddClient() {
    this.props.updateEditModal();
    this.props.updateClientName("");
    this.props.updateClientContact("");
    this.props.updateClientEmail("");
    this.props.updateClientAvailability(false);
    this.props.setIsUpdating(false);
  }
  handleClientStatus(id) {
    let client = this.props.clients.clients.filter((data) => data.id === id);
    client = client[0];
    this.props.updateClientStatus(client);
    if (client["online"] === false) {
      this.setState({ status: "Activate" });
    } else {
      this.setState({ status: "Deactivate" });
    }
    this.props.updateStatusModal();
  }
  handleUpdateClient(id) {
    let client = this.props.clients.clients.filter((data) => data.id === id);
    client = client[0];
    this.props.updateClientStatus(client);
    this.props.updateClientName(client.organization_name);
    this.props.updateClientEmail(client.client_email);
    this.props.updateClientContact(client.client_contact);
    this.props.updateClientAvailability(client.online);
    this.props.setIsUpdating(true);
    this.props.updateEditModal();
  }
  submitStatusUpdate() {
    this.props.changeClientStatus(this.props.clients.clientToChange);
    this.props.updateStatusModal();
  }
  submitForm(e) {
    e.preventDefault();
    let error = false;
    if (this.props.clients.clientName.trim().length === 0) {
      this.setState({ nameError: true });
      error = true;
    }
    this.setState({ error: error });
    if (!error) {
      if (this.props.clients.isUpdating) {
        this.props.updateExistingClient({
          id: this.props.clients.clientToChange.id,
          organization_name: this.props.clients.clientName,
          client_contact: this.props.clients.clientContact,
          client_email: this.props.clients.clientEmail,
          online: this.props.clients.online,
        });
      } else {
        this.props.saveClient({
          organization_name: this.props.clients.clientName,
          client_contact: this.props.clients.clientContact,
          client_email: this.props.clients.clientEmail,
          online: this.props.clients.online,
        });
      }
      this.props.updateEditModal();
    }
  }
  render() {
    return (
      <div>
        <Grid style={{ paddingBottom: "25px" }}>
          <Grid.Row>
            <Grid.Column>
              <Header style={{ display: "inline" }}>
                {this.props.t("general.learningInstitutions")}
              </Header>
              <Button
                floated="right"
                color="green"
                onClick={() => this.handleAddClient()}
              >
                {this.props.t("InstitutionsTable.addInstitution")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          size="small"
          open={this.props.clients.showStatusModal}
          className="modalHOTFIX"
          closeIcon
          onClose={() => {
            this.props.updateStatusModal();
          }}
        >
          <Modal.Header>
            {this.state.status} {this.props.t("InstitutionsTable.institution")}
          </Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to {this.state.status} this Institution?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => {
                this.props.updateStatusModal();
              }}
            >
              {this.props.t("general.no")}
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={this.submitStatusUpdate}
            />
          </Modal.Actions>
        </Modal>
        <Modal
          size="small"
          open={this.props.clients.showEditModal}
          className="modalHOTFIX"
          closeIcon
          onClose={() => {
            this.props.updateEditModal();
          }}
        >
          <Modal.Header>
            {this.props.t("InstitutionsTable.addOrEditInstitution")}
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Message
                visible={this.state.error}
                onDismiss={() => this.setState({ error: false })}
                header={this.props.t("InstitutionsTable.errorAddingClient")}
                content={this.props.t("general.missingFieldsErrorMessage")}
                error
              />
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-name"
                      error={this.state.nameError}
                      name="name"
                      value={this.props.clients.clientName}
                      onChange={(e) => this.updateClientName(e.target.value)}
                      control={Input}
                      label={this.props.t("InstitutionsTable.institutionName")}
                      onBlur={(e) =>
                        e.target.value.length === 0
                          ? this.setState({ nameError: true })
                          : this.setState({ nameError: false })
                      }
                    />
                    <Form.Field
                      id="form-input-control-contact"
                      name="contact"
                      value={this.props.clients.clientContact}
                      onChange={(e) => this.updateClientContact(e.target.value)}
                      control={Input}
                      label={this.props.t(
                        "InstitutionsTable.pointOfContactName"
                      )}
                    />
                    <Form.Field
                      id="form-input-control-email"
                      name="email"
                      value={this.props.clients.clientEmail}
                      onChange={(e) => this.updateClientEmail(e.target.value)}
                      control={Input}
                      label={this.props.t(
                        "InstitutionsTable.pointOfContactEmail"
                      )}
                    />
                    <Grid.Column width="5">
                      <Grid.Column width="11">
                        <label>
                          {this.props.t("InstitutionsTable.availabilityStatus")}
                        </label>
                      </Grid.Column>
                      <Checkbox
                        onChange={(e, data) =>
                          this.updateClientAvailability(data.checked)
                        }
                        label={this.props.t("general.online")}
                        checked={this.props.clients.online}
                      />
                    </Grid.Column>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      onClick={() => this.props.updateEditModal()}
                      color="red"
                      floated="right"
                    >
                      {this.props.t("general.cancel")}
                    </Button>
                    <Button
                      onClick={(e) => this.submitForm(e)}
                      color="green"
                      floated="right"
                    >
                      {this.props.t("general.save")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>
        <BasicTable
          columns={[
            {
              headerName: this.props.t("general.name"),
              field: "organization_name",
              cellStyle: {
                verticalAlign: "middle",
                alignSelf: "center",
              },
              flex: 2,
            },
            {
              headerName: this.props.t("InstitutionsTable.pointOfContact"),
              field: "client_contact",
              cellStyle: {
                verticalAlign: "middle",
                alignSelf: "center",
              },
              flex: 2,
            },
            {
              headerName: this.props.t("InstitutionsTable.contactEmail"),
              field: "client_email",
              cellStyle: {
                verticalAlign: "middle",
                alignSelf: "center",
              },
              flex: 2,
            },
            {
              field: "online",
              headerName: this.props.t("general.online"),
              cellRenderer: CheckMarkRenderer,
              cellStyle: {
                textAlign: "center",
                verticalAlign: "middle",
                alignSelf: "flex-end",
              },
              flex: 1,
            },
            {
              headerName: this.props.t("general.actions"),
              cellRenderer: DropdownRenderer,
              cellRendererParams: {
                text: this.props.t("general.view"),
              },
              valueGetter: (row) => {
                return [
                  {
                    text: this.props.t("general.edit"),
                    onClick: () => this.handleUpdateClient(row.data.id),
                  },
                  {
                    text:
                      row.data.online === true
                        ? this.props.t("general.deactivate")
                        : this.props.t("general.activate"),
                    onClick: () => this.handleClientStatus(row.data.id),
                  },
                ];
              },
              cellStyle: {
                overflow: "inherit",
                textAlign: "center",
                alignSelf: "flex-end",
              },
              flex: 2,
            },
          ]}
          data={this.props.clients.clients}
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect((state) => ({ clients: state.clients }), {
    fetchClients,
    updateEditModal,
    updateStatusModal,
    updateClientStatus,
    updateClientAvailability,
    changeClientStatus,
    updateClientName,
    updateClientContact,
    updateClientEmail,
    setIsUpdating,
    updateExistingClient,
    saveClient,
  })(InstitutionsTable)
);
