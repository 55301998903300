// Sentry initialization. Needs to be imported first
import "./instrument";
import React from "react";
import { createRoot } from "react-dom/client";
import "semantic-ui-css/semantic.min.css";
import App from "./containers/App";
import Keycloak from "keycloak-js";
import axios from "axios";
import { Provider } from "react-redux";
import { createOrUpdateUser } from "./lib/userServices";
import i18n from "./i18n";
import moment from "moment";
import setupStore from "./setupStore";

//window.localStorage.setItem('debug', '* -engine* -socket* -RIE* *WARN* *ERROR*');

const store = setupStore();

const kc = Keycloak("/keycloak.json");

kc.init({ onLoad: "check-sso" }).success((authenticated) => {
  if (authenticated) {
    createOrUpdateUser({
      user_id: kc.tokenParsed.sub,
      username: kc.tokenParsed.preferred_username,
      first_name: kc.tokenParsed.given_name,
      last_name: kc.tokenParsed.family_name,
      learning_institution: kc.tokenParsed.learning_institution,
      dob: kc.tokenParsed.birthday,
      phone: kc.tokenParsed.phone,
      province: kc.tokenParsed.province,
      timezone: kc.tokenParsed.timezone,
      locale: kc.tokenParsed.locale,
    });
    //check if users language is in localStorage and if not check keycloak lang
    const lang = localStorage.getItem("lang") || kc.tokenParsed.locale || "en";
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    moment.locale(lang);

    setInterval(() => {
      kc.updateToken(10).error(() => kc.logout());
    }, 10000);

    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(
      <Provider store={store}>
        <App keycloak={kc} />
      </Provider>
    );
  } else {
    // show possibly other page here...
    kc.login();
  }
});

axios.interceptors.request.use(
  (config) => {
    let token = kc.token;
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
