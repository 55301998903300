import React from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import {
  ClientSideRowModelModule,
  ModuleRegistry,
  TooltipModule,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

// Register all Community features
ModuleRegistry.registerModules([ClientSideRowModelModule, TooltipModule]);

// Style that allows dropdowns to appear on top of grid rows
const GridWrapper = styled.div`
  & .ag-row.ag-row-focus {
    z-index: 1;
  }
`;

function BasicTable({ columns, data }) {
  return (
    <GridWrapper>
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          rowData={data}
          columnDefs={columns}
          pagination={true}
          tooltipShowDelay={500}
          tooltipInteraction={true}
        />
      </div>
    </GridWrapper>
  );
}

export default withTranslation()(BasicTable);
